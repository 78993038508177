import React from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import styled from "styled-components";

export interface ILink {
  to: LinkProps["to"];
  target?: string
}

const StyledLink = styled(RouterLink)`
  ${({ theme }) => `
    color: ${theme.palette.neutral.grey};
    display: block;
  `};
`;

const Link: React.FC<ILink> = ({
                                 children,
                                 to,
                                 target
                               }) => (
  <StyledLink target={target} to={to}>{children}</StyledLink>
);

export { Link };
