import React, { useEffect, useState } from "react";
import { Flex, Box, Line } from "components";
import styled from "styled-components";
import loginImg from "assets/images/kozminski_venture_lab_gray_cropped.svg";
import { Footer } from "components/Footer";
import { Menu } from "components/Menu";
import { sideMenuItems, IMenuItem } from "constatns/menuItems";
import { useNavigate } from "react-router-dom";
import { useWindowWidth } from "hooks/useWindowWidth";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";

interface ISideBar {
  logged: boolean;
  onMenuIconClick: () => void;
}

const Wrapper = styled(Flex)`
  ${({ theme }) => `
    position: absolute;
    height: 100%;
    z-index: 10;
    overflow: hidden;
    min-width: 215px;
    background-color: ${theme.palette.neutral.white};
    box-shadow: 0 0px 4px  ${theme.palette.neutral.medium};    
  `}
`;

const Image = styled.div`
  width: 204px;
  height: 94px;
  background-image: url(${loginImg});
  background-repeat: no-repeat;
  bacground-size: cover;
  background-size: 100% 100%;
  cursor: pointer;
`;

const CustomMenuWrapper = styled(Flex)`
  width: 100%;
`;

export const SideBar = ({ logged, onMenuIconClick }: ISideBar) => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState<Array<IMenuItem>>([]);

  const windowWidth = useWindowWidth();

  const privatePathGuard = (
    isLogged: boolean,
    itemsToCheck: Array<IMenuItem>
  ) => {
    isLogged
      ? setMenu(sideMenuItems)
      : setMenu(itemsToCheck.filter((item) => isLogged === item.private));
  };

  const setPath = () => {
    return "/app";
  };
  const moveToActiveChallengesPage = () => {
    navigate("/app");
    setPath();
  };

  useEffect(() => {
    privatePathGuard(logged, sideMenuItems);
  }, [logged]);

  return (
    <Wrapper alignItems="center" flexDirection="column">
      {windowWidth > 980 ? (
        <Box mt={-4}>
          <Image onClick={moveToActiveChallengesPage} />
          <Box mt={-7} mx={2}>
            <Line />
          </Box>
        </Box>
      ) : (
        <Box ml={-25} mt={4}>
          <MenuIcon onClick={onMenuIconClick} />
        </Box>
      )}
      <CustomMenuWrapper flexGrow={3} alignItems="flex-start">
        <Menu onMenuIconClick={onMenuIconClick} items={menu} />
      </CustomMenuWrapper>
      <Footer />
    </Wrapper>
  );
};
