import { http } from "utilities/http";
import { IUserWithToken } from "types/user";
import {
  LOGIN_URL,
  REGISTER_URL,
  PASSWORD_RESET,
  LOGOUT_URL,
} from "constatns/paths";
import {
  ILoginFormData,
  IRegisterUserRequest,
  IResetPassword,
  IChangePassword,
} from "types/forms";

export const loginUser = async (data: ILoginFormData) => {
  const userData = await http<IUserWithToken>(LOGIN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user: data }),
  });
  return userData;
};

export const registerUser = async (user: IRegisterUserRequest) => {
  const response = await http<IUserWithToken>(REGISTER_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });
  return response;
};

export const resetUserPassword = async (user: IResetPassword) => {
  await http(PASSWORD_RESET, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });
};

export const changeUserPassword = async (user: IChangePassword) => {
  const passwordData = await http(PASSWORD_RESET, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user }),
  });
  return passwordData;
};

export const logoutUser = () =>
  http(LOGOUT_URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
