export const stringToArray = (text: string = "", separator: string): string[] => {
  const arr = text?.split(separator) || [];
  return arr;
};

export const openLinkFromAnotherDomain = (link: string) => {
  // solution for safari
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = link;
  a.target = "_blank";
  a.click();
  document.body.removeChild(a);
};

export const downloadURI = (uri: string, name: string) => {
  fetch(uri).then(function(t) {
    return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", name);
        a.click();
      }
    );
  });
};
