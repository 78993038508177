import styled, { css } from "styled-components";
import {
  flexbox,
  FlexboxProps,
  gridColumnGap,
  GridColumnGapProps,
  space,
  SpaceProps,
} from "styled-system";

interface IFlex extends SpaceProps, FlexboxProps, GridColumnGapProps {}

export const Flex = styled.div<IFlex>(
  () =>
    css`
      display: flex;
    `,
  space,
  flexbox,
  gridColumnGap
);
