import React from "react";
import { Typography, Button, TextField, Flex, Box } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import { ReactComponent as Email } from "assets/icons/envelope-password-fill.svg";

import { useForm } from "react-hook-form";
import { resetPassword } from "schemas/resetPassword";
import { useResetPassword } from "hooks/useResetPassword";
import { ErrorHelperText } from "components/ErrorHelperText";
import { ILoginFormData } from "types/forms";
import { useWindowWidth } from "hooks/useWindowWidth";

const Wrapper = styled(Flex)`
  width: 322px;
`;

export const ResetPassword = () => {
  const { resetUserPwd, error } = useResetPassword();
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<ILoginFormData>({
    mode: "onChange",
    resolver: yupResolver(resetPassword),
  });

  const windowWidth = useWindowWidth();

  const onSubmit = async (data: ILoginFormData) => {
    resetUserPwd(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper flexDirection="column" alignItems="center" mt={32}>
        <Typography variant="h1" color="primary" fontWeight={500} mb={16}>
          Reset password
        </Typography>
        <Box width={windowWidth > 980 ? "100%" : "80%"} mb={3}>
          <TextField
            label="Enter an e-mail to get a link."
            transparent
            icon={<Email />}
            {...register("email")}
            error={errors.email?.message}
          ></TextField>
          {error && <ErrorHelperText>{error}</ErrorHelperText>}
        </Box>

        <Box mt={6} mb={8} width="125px">
          <Button
            variant="blue"
            fullWidth
            type="submit"
            disabled={!formState.isValid}
          >
            Send
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};
