import React, { useState } from "react";
import { Flex, Card, Typography, Box } from "components";
import styled from "styled-components";
import { useChallengeDedails } from "hooks/useChallengeDedails";
import { useParams, useNavigate } from "react-router-dom";
import { IFetchTeamResponse } from "types/teams";
import { ChallengeDetailHeader } from "./ChallengeDetailHeader";
import { GoogleForm } from "components/GoogleForm";
import { useWindowWidth } from "hooks/useWindowWidth";

export interface IChallengeDetails {
  horizontal?: boolean;
  waiting?: boolean;
  pending?: boolean;
}

export interface ICommonSection {
  horizontal?: boolean;
}

const StyledCard = styled(Card)`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: ${theme.space[10]}px 0px;
  `}
`;

export const ChallengeDetailSurvey = () => {
  const { id } = useParams<{id: string}>()
  const parsedId = parseInt(id as string, 10);
  const { responseData } = useChallengeDedails(parsedId);
  const [teamInvitation, setTimInvitation] = useState<IFetchTeamResponse>();

  const windowWidth = useWindowWidth();

  return (
    <StyledCard>
      {responseData && (
        <>
          <Flex pt={6} px={windowWidth > 980 ? 13 : 2} flexGrow={1} flexDirection="column">
            <ChallengeDetailHeader res={responseData} />
            <GoogleForm googleFormUrl={responseData.google_form_url} />
          </Flex>
        </>
      )}
    </StyledCard>
  );
};
