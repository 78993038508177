import { useState } from "react";
import { useSpinnerContext } from "hooks/useSpinnerContext";
import { useAuthDispatchContext } from "hooks/useAuthContext";
import { loginUser } from "services/auth";
import { useNavigate } from "react-router-dom";
import { ILoginFormData } from "types/forms";

interface IUseLogin {
  login: (data: ILoginFormData) => void;
  error: string | null;
}

export const useLogin = (): IUseLogin => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { setIsLoading } = useSpinnerContext();
  const { setToken, setUserProfile } = useAuthDispatchContext();

  const login = async (data: ILoginFormData) => {
    setError("");
    setIsLoading(true);

    try {
      const response = await loginUser(data);
      if (!response) return;
      setToken(response.token);
      setUserProfile(response);
      navigate("/app");
    } catch (err: any) {
      setError(err.error);
    } finally {
      setIsLoading(false);
    }
  };

  return { login, error };
};
