import React from "react";
import styled from "styled-components";

const Root = styled.div`
  ${({ theme }) => `
    border-radius: ${theme.radii[2]}px;
    background-color: ${theme.palette.neutral.white};
    box-shadow: 0 6px 24px -12px ${theme.palette.neutral.medium};
    overflow: hidden;
  `};
`;

export const Card: React.FC = ({ children, ...props }) => (
  <Root {...props}>{children}</Root>
);
