import React from "react";
import styled from "styled-components";
import kbhImg from "assets/images/kbh-hub.svg";

export default styled.div<{ imageUrl?: string }>`
  width: 193px;
  height: 65px;
  ${({ imageUrl }) => `
    background-image: url(${imageUrl || kbhImg});
  `};
  background-repeat: no-repeat;
  background-size: contain;
`;
