import React from "react";
import { Typography, Flex } from "components";
import styled from "styled-components";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";

const StyledFlex = styled(Flex)`
  ${({ theme }) => `    
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0.8;
    background-color: ${theme.palette.neutral.dark};
  `};
`;

const override = css`
  display: block;
  margin: 0 auto;
`;

export const Spinner = () => {
  return (
    <StyledFlex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <MoonLoader color="white" loading={true} css={override} size={150} />
      <Typography mt={6} variant="h1" color="white">
        LOADING...
      </Typography>
    </StyledFlex>
  );
};
