import React from "react";
import styled from "styled-components";
import { Typography, Link, Flex } from "components";
import { ReactComponent as YouTubeIcon } from "assets/icons/ytube.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedIn.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/fbook.svg";
import { useWindowWidth } from "hooks/useWindowWidth";

const Wrapper = styled(Flex)`
  height: 166px;
  width: 100%;
  background-color: ${({ theme }) => `${theme.palette.neutral.mainBlue};`};
`;

const SocialMediaWrapper = styled(Flex)`
  width: 172px;
  a {
    text-decoration: none;
  }
`;

const VerticalLine = styled.div`
  height: 100%;
  width: 1px;
  ${({ theme }) => `background-color: ${theme.palette.neutral.white}`}
`;

export const LandingPageFooter = () => {
  const windowWidth = useWindowWidth();

  return (
    <Wrapper justifyContent={windowWidth > 980 ? "start" : "center"} pt={14}>
      <SocialMediaWrapper
        ml={windowWidth > 980 ? 30 : 0}
        flexDirection="column"
      >
        <Flex justifyContent="space-between">
          <Link to="/about">
            <Typography variant="body" color="white">
              ABOUT US
            </Typography>
          </Link>
          <VerticalLine />
          <Link to="/contact">
            <Typography variant="body" color="white">
              CONTACT
            </Typography>
          </Link>
        </Flex>
        <Flex mt={4} justifyContent="space-between">
          <a
            href="https://www.youtube.com/channel/UCcgXEzT2DUfgux_Q8GDLvYg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTubeIcon />
          </a>
          <a
            href="https://www.instagram.com/kozminskihub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://www.linkedin.com/company/kozminski-business-hub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </a>
          <a
            href="https://www.facebook.com/KozminskiBusinessHub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
        </Flex>
      </SocialMediaWrapper>
    </Wrapper>
  );
};
