import { AuthContext, AuthDispatchContext } from "contexts/AuthContext";
import { useContext } from "react";
import { IAuthContext, IAuthDispatchContext } from "../contexts/AuthContext";

export const useAuthStateContext = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      "useAuthContext must be inside a AuthContextProvider with a value"
    );
  }
  return context;
};

export const useAuthDispatchContext = (): IAuthDispatchContext => {
  const context = useContext(AuthDispatchContext);
  if (!context) {
    throw new Error(
      "useAuthDispatchContext must be inside a AuthStateDispatchProvider with a value"
    );
  }
  return context;
};
