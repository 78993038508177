import React, { useState, useEffect } from "react";
import { Flex, Card, Button, Typography, Box, Link } from "components";
import styled from "styled-components";
import { useChallengeDedails } from "hooks/useChallengeDedails";
import { useParams, useNavigate } from "react-router-dom";
import { IFetchTeamResponse } from "types/teams";
import { fetchTeam, joinTeam } from "services/teams";
import { deleteInvitations } from "services/invitations";
import { useSpinnerContext } from "hooks/useSpinnerContext";
import { toast } from "react-toastify";
import { useAuthStateContext } from "hooks/useAuthContext";
import { Checkbox } from "components/Checkbox";
import { useForm, Controller } from "react-hook-form";
import { openLinkFromAnotherDomain } from "helpers/methods";
import { ChallengeDetailHeader } from "./ChallengeDetailHeader";

export interface IChallengeDetails {
  horizontal?: boolean;
  waiting?: boolean;
  pending?: boolean;
}

export interface ICommonSection {
  horizontal?: boolean;
}

const StyledCard = styled(Card)`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: ${theme.space[10]}px 0px;
  `}
`;

const StyledItemWrapper = styled(Flex)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.lightBlue};
    height: 44px; 
    max-width: 240px  
  `}
`;

export const ChallengeDetaiITeamInfo: React.FC = () => {
  const { id, team } = useParams() as { id: string; team: string };
  const parsedId = parseInt(id as string, 10);
  const { responseData } = useChallengeDedails(parsedId);
  const [teamInvitation, setTimInvitation] = useState<IFetchTeamResponse>();
  const { setIsLoading } = useSpinnerContext();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>();
  const navigate = useNavigate();
  const { userProfile } = useAuthStateContext();
  const { formState, control } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    fetchTeam(team).then((res) => setTimInvitation(res));
  }, []);

  useEffect(() => {
    setIsButtonDisabled(!formState.isValid);
  }, [formState]);

  const renderParticipants = () => {
    return teamInvitation?.data.invitations.map((el) => {
      return (
        <StyledItemWrapper key={el.id} alignItems="center" pl={4} mb={2}>
          {el.recipient}
        </StyledItemWrapper>
      );
    });
  };

  const handleJoinTeam = () => {
    setIsLoading(true);
    joinTeam(team, { accepted_regulation: true })
      .then((res) => navigate("/app/my-challenges"))
      .catch((err) => toast.error("Failed to join the team."))
      .finally(() => setIsLoading(false));
  };

  const removeInvitation = async () => {
    setIsLoading(true);
    deleteInvitations(team)
      .then((res) => navigate(-1))
      .catch((err) => toast.error("Failed to delete the team."))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (formState.isValid === formState.isDirty) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [formState]);

  return (
    <StyledCard>
      {responseData && (
        <>
          <Flex pt={6} px={13} flexGrow={1} flexDirection="column">
            <ChallengeDetailHeader res={responseData} />
            <Box ml={6}>
              <Typography variant="body" fontWeight={500} mt={6} mb={4}>
                {teamInvitation?.data.name}
              </Typography>
              <Box mb={6}>
                {teamInvitation?.data.users[0].id === userProfile?.id ? (
                  <Button
                    variant="lightBlue"
                    type="submit"
                    onClick={removeInvitation}
                  >
                    Delete team
                  </Button>
                ) : null}
              </Box>
              {renderParticipants()}
              <form>
                <Flex mt={6} flexDirection="column">
                  {responseData.regulations.map((el, index) => (
                    <Flex mb={2} key={index + el.id}>
                      <Controller
                        control={control}
                        name={`regulationAcceptation_${el.id}_${index}`}
                        rules={{ required: el.required }}
                        render={({ field: { onChange } }) => (
                          <Checkbox
                            error={
                              !!formState.errors[
                                `regulationAcceptation_${el.id}_${index}`
                              ]
                            }
                            onChange={onChange}
                            id={`regulationAcceptation_${el.id}_${index}`}
                          />
                        )}
                      />
                      {el.regulation_url ? (
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            openLinkFromAnotherDomain(el.regulation_url);
                          }}
                        >
                          <Link target="_blank" to={el.regulation_url}>
                            {el.link_name}
                          </Link>
                        </span>
                      ) : (
                        <label
                          htmlFor={`regulationAcceptation_${el.id}_${index}`}
                        >
                          {el.link_name}
                        </label>
                      )}
                    </Flex>
                  ))}
                </Flex>
              </form>
            </Box>
          </Flex>
          <Flex justifyContent="flex-end" mb={30} mr={30}>
            {teamInvitation?.data.users[0].id !== userProfile?.id ? (
              <Button
                variant="blue"
                onClick={handleJoinTeam}
                disabled={!isButtonDisabled}
              >
                Join
              </Button>
            ) : null}
          </Flex>
        </>
      )}
    </StyledCard>
  );
};
