import React from "react";
import styled from "styled-components";

import { Flex, Box } from "components";

import loginImg from "assets/images/kozminski_venture_lab_gray_RGB_2.svg";
import { Outlet } from "react-router-dom";

const Image = styled.div`
  width: 282px;
  height: 98px;
  background-image: url(${loginImg});
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @media only screen and (max-width: 980px) {
    display: none;
  }
`;

const Wrapper = styled(Flex)`
  min-width: 400px;
  border-radius: 5px;
  background-color: ${({ theme }) => `${theme.palette.neutral.white}`};

  @media only screen and (max-width: 980px) {
    min-width: 100%;
  }
`;

export const SignInSingnUpLayout = () => {
  return (
    <Wrapper flexDirection="column" alignItems="center">
      <Box mt={4}>
        <Image />
      </Box>
      <Outlet />
    </Wrapper>
  );
};
