export const http = async <T = null>(
  url: string,
  options?: RequestInit
): Promise<T> => {
  const response = await fetch(url, options);

  if (!response.ok) {
    const error = await response.json();
    throw error;
  }

  if (response.statusText === "No Content") {
    return new Promise((resolve) => {
      resolve(null as any);
    });
  }

  return response.json();
};
