import { useEffect, useState, useCallback } from "react";
import { getInvitations } from "services/invitations";
import { useSpinnerContext } from "hooks/useSpinnerContext";
import { IInvitation } from "../types/invitations";

interface IUseChallengeDedails {
  invitations: IInvitation[];
  error: string | null;
  setInvitations: React.Dispatch<React.SetStateAction<IInvitation[]>>;
}

export const useInvitations = (id: number): IUseChallengeDedails => {
  const [error, setError] = useState("");
  const { setIsLoading } = useSpinnerContext();
  const [invitations, setInvitations] = useState<IInvitation[]>([]);

  const getInvitationData = useCallback(
    async (id: number) => {
      setError("");
      setIsLoading(true);
      try {
        const response = await getInvitations(id);
        if (!response) return;
        setInvitations(response.data);
      } catch (err: any) {
        setError(err.error);
      } finally {
        setIsLoading(false);
      }
    },
    [id]
  );

  useEffect(() => {
    getInvitationData(id);
  }, []);

  return { invitations, error, setInvitations };
};
