import React from "react";
import { Typography, Button, Link, TextField, Flex, Box } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import { ReactComponent as Email } from "assets/icons/user.svg";
import { ReactComponent as Password } from "assets/icons/lock-password-fill.svg";
import { useForm, Controller } from "react-hook-form";
import { registerSchema } from "schemas/register";
import { Checkbox } from "components/Checkbox";
import { ErrorHelperText } from "components/ErrorHelperText";
import { useRegister } from "hooks/useRegister";

interface IRegisterFormData {
  email: string;
  password: string;
  password_confirmation: string;
  first_name: string;
  last_name: string;
  rules: boolean;
  agreement: boolean;
  newsletterAgreement: boolean;
}

const Wrapper = styled(Flex)`
  @media only screen and (min-width: 980px) {
    width: 322px;
  }
`;

const StyledBoxWrapper = styled(Box)`
  width: 100%;

  > label {
    cursor: pointer;
    ${({ theme }) => `
      color: ${theme.palette.neutral.grey};
    `};
  }

  @media only screen and (max-width: 980px) {
    width: 90%;
  }
`;

const initialValues = {
  email: "",
  password: "",
  password_confirmation: "",
  first_name: "",
  last_name: "",
  rules: false,
  agreement: false,
};

export const SignUp = () => {
  const { signup, error } = useRegister();

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
    control,
  } = useForm<IRegisterFormData>({
    mode: "onChange",
    resolver: yupResolver(registerSchema),
    defaultValues: initialValues,
  });

  const onSubmit = async (data: IRegisterFormData) => {
    signup(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper flexDirection="column" alignItems="center" mt={4} pb={5}>
        <Typography variant="h1" color="primary" fontWeight={500} mb={11}>
          Sign up
        </Typography>
        <StyledBoxWrapper mb={3}>
          <TextField
            label="User mail"
            transparent
            icon={<Email />}
            {...register("email")}
            error={errors.email?.message}
          ></TextField>
        </StyledBoxWrapper>
        <StyledBoxWrapper mb={2}>
          <TextField
            type="password"
            label="Password"
            transparent
            icon={<Password />}
            {...register("password")}
            error={errors.password?.message}
          ></TextField>
        </StyledBoxWrapper>
        <StyledBoxWrapper mb={2}>
          <TextField
            type="password"
            label="Password again"
            transparent
            icon={<Password />}
            {...register("password_confirmation")}
            error={errors.password_confirmation?.message}
          ></TextField>
        </StyledBoxWrapper>
        <StyledBoxWrapper mb={3}>
          <TextField
            label="Your first name"
            transparent
            icon={<Email />}
            {...register("first_name")}
            error={errors.first_name?.message}
          ></TextField>
        </StyledBoxWrapper>
        <StyledBoxWrapper mb={3}>
          <TextField
            label="Your last name"
            transparent
            icon={<Email />}
            {...register("last_name")}
            error={errors.last_name?.message}
          ></TextField>
          {error && <ErrorHelperText>{error}</ErrorHelperText>}
        </StyledBoxWrapper>
        <StyledBoxWrapper
          display="flex"
          justifyContent="start"
          mb={4}
          alignItems="center"
        >
          <Controller
            control={control}
            name="rules"
            render={({ field }) => <Checkbox onChange={field.onChange} />}
          />
          <Link to="/Regulamin_platformy_ENG.pdf" target="_blank">
            I accept the terms and conditions of the Kozminski Venture Lab
            platform
          </Link>
        </StyledBoxWrapper>
        <StyledBoxWrapper
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <Controller
            control={control}
            name="agreement"
            render={({ field }) => <Checkbox onChange={field.onChange} />}
          />
          <Link
            to="/ENG_KLAUZULA_INFORMACYJNA-_zgoda_marketingowa.docx.pdf"
            target="_blank"
          >
            I agree to receive the newsletter - information about events
            organized by Kozminski Business Hub and other product new
          </Link>
        </StyledBoxWrapper>
        <StyledBoxWrapper
          display="flex"
          justifyContent="start"
          alignItems="center"
          mt={2}
        ></StyledBoxWrapper>
        <Box mt={6} mb={8} width="125px">
          <Button
            variant="blue"
            fullWidth
            type="submit"
            disabled={!formState.isValid}
          >
            Sign Up
          </Button>
        </Box>
        <Flex>
          <Typography variant="body" color="primary" mr={1}>
            You already have an account?
          </Typography>
          <Link to="/">
            <Typography variant="h3" color="coloured">
              Sign in
            </Typography>
          </Link>
        </Flex>
      </Wrapper>
    </form>
  );
};
