import { GET_INVITATIONS, REMOVE_INVITATIONS } from "constatns/paths";
import { fetchAPI } from "utilities/fetchAPI";
import { IGetInvitationsResponse } from "types/invitations";

export const getInvitations = async (
  teamId: string | number
): Promise<IGetInvitationsResponse> => {
  const response = await fetchAPI<IGetInvitationsResponse>(
    GET_INVITATIONS(teamId),
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return response;
};

export const deleteInvitations = async (
  teamId: string | number
): Promise<void> => {
  await fetchAPI(REMOVE_INVITATIONS(teamId), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
