import { Configuration } from "react-grid-system";

import { breakpoints } from "./breakpoints";

export const containerWidths = [540, 740, 960, 1140, 1510];

export const gridConfig = {
  maxScreenClass: "xxl" as Configuration["maxScreenClass"],
  defaultScreenClass: "xxl" as Configuration["defaultScreenClass"],
  gutterWidth: 30,
  containerWidths,
  breakpoints,
};
