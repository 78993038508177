import React from "react";
import { Flex } from "components";
import styled from "styled-components";

const StyledFlex = styled(Flex)`
  width: 100%;
  height: 100%;
  font-size: 50px;
`;

export const BlankPage = () => {
  return (
    <StyledFlex alignItems="center" justifyContent="center">
      BLANK PAGE FOR DEV PURPOSE ONLY
    </StyledFlex>
  );
};
