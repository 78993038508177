import React from "react";
import { Button, Flex, Box } from "components";
import { Avatar } from "components";
import styled from "styled-components";
import { Dropdown } from "components/Dropdown";
import { useNavbar } from "hooks/useNavbar";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";

export interface INavbar {
  logged: boolean;
  onMenuIconClick: () => void;
}

const Wrapper = styled(Flex)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
    width: 100%;
    min-height: 64px;
  `}
`;

export const Navbar: React.FC<INavbar> = ({ logged, onMenuIconClick }) => {
  const { logout, moveToSignIn, moveToSignUp } = useNavbar();

  const profileDropdownItems = [{ label: "Logout", onClick: logout }];

  return (
    <Wrapper alignItems="center" justifyContent="space-between">
      <Box ml={10}>
        <MenuIcon onClick={onMenuIconClick} />
      </Box>
      {logged ? (
        <Box mr={10}>
          <Dropdown items={profileDropdownItems}>
            <Avatar border />
          </Dropdown>
        </Box>
      ) : (
        <Flex justifyContent='flex-end'>
          <Box mr={6}>
            <Button variant="blue" type="submit" onClick={moveToSignUp}>
              Sign up
            </Button>
          </Box>
          <Box mr={10}>
            <Button variant="lightBlue" type="submit" onClick={moveToSignIn}>
              Sign in
            </Button>
          </Box>
        </Flex>
      )}
    </Wrapper>
  );
};
