import { Typography } from "components";
import React, { useState } from "react";
import styled from "styled-components";

interface IProps {
  content?: string;
  fullWidth?: boolean;
}

const StyledWrapper = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) => `
    display: inline-block;
    position: relative;
    ${fullWidth ? `width: 100%;` : ""};
  `}
`;

const ContentWrapper = styled.div`
  ${({ theme }) => `
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px;
    color: ${theme.palette.neutral.white};
    background: ${theme.palette.neutral.dark};
    font-size: 10px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 100;
    top: -30px;
    width: 140px;

    ::before {
      content: " ";
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 6px;
      margin-left: -12px;
      top: 100%;
      border-top-color: ${theme.palette.neutral.dark};
    }
  `}
`;

export const Tooltip: React.FC<IProps> = ({ children, content, fullWidth }) => {
  const [active, setActive] = useState(false);

  const showTip = () => {
    setActive(true);
  };

  const hideTip = () => {
    setActive(false);
  };

  return (
    <StyledWrapper
      fullWidth={fullWidth}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && content && (
        <ContentWrapper>
          <Typography textAlign="center" variant="body2">
            {content}
          </Typography>
        </ContentWrapper>
      )}
    </StyledWrapper>
  );
};
