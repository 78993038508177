import React, { useEffect, useState } from "react";
import { Navbar } from "components/Navbar";
import { SideBar } from "components/SideBar";
import styled from "styled-components";
import { Flex } from "components";
import { Outlet } from "react-router-dom";
import { useWindowWidth } from "hooks/useWindowWidth";

const Wrapper = styled(Flex)`
  ${({ theme }) => `
    widht: 100%;
    height: 100%;
    background-color: ${theme.palette.neutral.lightGrey}
    `}
`;
const RightColumnGrid = styled(Flex)`
  ${({ theme }) => `
    height: 100%;
    overflow-y: scroll;

    @media only screen and (min-width: 980px) {
      margin-left: ${theme.space[45]}px;
    }
  `}
`;

const GridWrapper = styled(Flex)`
  width: 90%;
`;

export const MainView = (): JSX.Element => {
  const [myLocalStorageData, setMyLocalStorageData] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const windowWidth = useWindowWidth();

  const OnMenuIconClick = () => {
    setIsSideBarOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const data = localStorage.getItem("token");
    if (!data) return;
    setMyLocalStorageData(!!data);
  }, []);

  return (
    <Wrapper>
      {(isSideBarOpen || windowWidth > 980) && (
        <SideBar
          onMenuIconClick={OnMenuIconClick}
          logged={myLocalStorageData}
        />
      )}
      <Flex flexDirection="column" flexGrow={2}>
        <Navbar onMenuIconClick={OnMenuIconClick} logged={myLocalStorageData} />
        <RightColumnGrid justifyContent="center">
          <GridWrapper flexWrap="wrap" justifyContent="center">
            <Outlet />
          </GridWrapper>
        </RightColumnGrid>
      </Flex>
    </Wrapper>
  );
};
