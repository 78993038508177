import { Box } from "components/Box";
import { Flex } from "components/Flex";
import { Tooltip } from "components/Tooltip";
import { theme } from "config";
import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";

export interface ButtonI extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "blue" | "grey" | "lightBlue";
  disabled?: boolean;
  label?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  bordered?: boolean;
  tooltipContent?: string;
}

interface StyledButtonI {
  variant: NonNullable<ButtonI["variant"]>;
  icon?: ButtonI["icon"];
  fullWidth?: ButtonI["fullWidth"];
  bordered?: ButtonI["bordered"];
}

const { palette, textStyles } = theme;

const colorVariantsMap = {
  primary: palette.neutral.white,
  secondary: palette.primary.main,
  blue: palette.neutral.white,
  grey: palette.text.primary,
  lightBlue: palette.neutral.mainBlue,
};

const colorHoverVariantsMap = {
  primary: palette.neutral.white,
  secondary: palette.primary.main,
  blue: palette.neutral.white,
  grey: palette.text.primary,
  lightBlue: palette.neutral.white,
};

const colorDisabledVariantsMap = {
  primary: palette.neutral.white,
  secondary: rgba(palette.neutral.dark, 0.4),
  blue: palette.neutral.white,
  grey: palette.text.primary,
  lightBlue: palette.accent.lightBlue,
};

const backgroundColorVariantsMap = {
  primary: palette.primary.main,
  secondary: palette.neutral.white,
  blue: palette.neutral.mainBlue,
  grey: palette.neutral.light,
  lightBlue: palette.neutral.lightBlue,
};

const backgroundColorHoverVariantsMap = {
  primary: palette.primary.dark,
  secondary: palette.neutral.light,
  blue: palette.accent.lightBlue,
  grey: palette.neutral.veryLight,
  lightBlue: palette.accent.blue,
};

const backgroundColorDisabledVariantsMap = {
  primary: palette.neutral.medium,
  secondary: palette.neutral.veryLight,
  blue: palette.neutral.medium,
  grey: palette.neutral.veryLight,
  lightBlue: palette.neutral.veryLight,
};

const borderColorVariantsMap = {
  primary: palette.primary.main,
  secondary: palette.primary.main,
  blue: palette.accent.blue,
  grey: palette.neutral.light,
  lightBlue: palette.accent.lightBlue,
};

const StyledButton = styled.button<StyledButtonI>`
  ${({ variant, icon, fullWidth, bordered }) => `
    display: ${fullWidth ? "flex" : "inline-flex"};
    align-items: center;
    justify-content: center;    
    ${fullWidth ? `width: 100%;` : ""};
    min-height: 43px;
    border-radius: 5px;    
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: ${textStyles.body.fontSize}px;
    color: ${colorVariantsMap[variant]};
    background-color: ${backgroundColorVariantsMap[variant]};
    ${
      bordered
        ? `
          box-shadow: inset 0px 0px 0px 2px ${borderColorVariantsMap[variant]}; 
          border: 0;
        `
        : "border: 0;"
    }
    outline: none;
    cursor: pointer;
    ${icon ? "width: 40px;" : ""}
    ${!icon ? "padding: 10px 22px;" : ""}

    &:hover {
      color: ${colorHoverVariantsMap[variant]};
      background-color: ${backgroundColorHoverVariantsMap[variant]};
    }

    &:disabled {
      pointer-events: none;
      color: ${colorDisabledVariantsMap[variant]};
      background-color: ${backgroundColorDisabledVariantsMap[variant]};
    }
  `}
`;

export const Button: React.FC<ButtonI> = ({
  label,
  icon,
  bordered,
  variant = "primary",
  type = "button",
  disabled = false,
  fullWidth,
  onClick,
  children,
  tooltipContent,
}) => (
  <Tooltip fullWidth={fullWidth} content={tooltipContent}>
    <StyledButton
      type={type}
      icon={icon}
      variant={variant}
      disabled={disabled}
      fullWidth={fullWidth}
      bordered={bordered}
      onClick={onClick}
    >
      <Flex justifyContent="center" alignItems="center">
        {label}
        {icon}
        {children && <Box mx={1}>{children}</Box>}
      </Flex>
    </StyledButton>
  </Tooltip>
);
