import styled from "styled-components";

export const Line = styled.span`
  ${({ theme }) => `
	    display: flex;
      background-color: ${theme.palette.neutral.lightGrey};
      margin: ${theme.space[4]}px 0;
      width: 100%;
      height: 1px;
  `};
`;
