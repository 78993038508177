import { createContext, useState } from "react";

type SpinnerContextType = {
  isLoading: boolean | null;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean | null>>;
};

export const SpinnerContext = createContext<SpinnerContextType | null>(null);

export const SpinnerContextProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState<boolean | null>(null);

  return (
    <SpinnerContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </SpinnerContext.Provider>
  );
};
