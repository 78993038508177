import { useNavigate } from "react-router-dom";
import { logoutUser } from "services/auth";
import { useSpinnerContext } from "hooks/useSpinnerContext";
import { useAuthDispatchContext } from "./useAuthContext";

interface IUseNavbar {
  logout: () => void;
  moveToMyProfile: () => void;
  moveToSignIn: () => void;
  moveToSignUp: () => void;
  moveToProfileSettings: () => void;
}

export const useNavbar = (): IUseNavbar => {
  const navigate = useNavigate();
  const { setIsLoading } = useSpinnerContext();
  const { setToken, setUserProfile } = useAuthDispatchContext();

  const moveToSignIn = () => {
    navigate("/");
  };

  const moveToSignUp = () => {
    navigate("/signup");
  };

  const moveToMyProfile = () => {
    navigate("profile");
  };

  const moveToProfileSettings = () => {
    navigate("profile/settings");
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      await logoutUser();
      setToken(null);
      setUserProfile(null);
      setIsLoading(false);
      navigate("/");
    } catch {
      setIsLoading(false);
    }
  };

  return {
    logout,
    moveToMyProfile,
    moveToSignIn,
    moveToSignUp,
    moveToProfileSettings,
  };
};
