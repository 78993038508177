import { Box, Flex } from "components";
import { LandingPage } from "components/LandingPage";
import { LandingPageFooter } from "components/LandingPage/LandingPageFooter";
import { LandingPageSlider } from "components/LandingPage/LandingPageSlider";
import { SignInSingnUpLayout } from "components/SignInSingnUpLayout";
import React from "react";
import styled from "styled-components";
import { useWindowWidth } from "hooks/useWindowWidth";

const Wrapper = styled(Flex)`
  width: 100%;
  background-color: ${({ theme }) => `${theme.palette.neutral.mainBlue};`};
`;

const TextContentWrapper = styled(Box)`
  ${({ theme }) => `
    color: ${theme.palette.neutral.white};
    font-weight: 500;
    text-align: center;
    font-size: 20px;
    margin-bottom: ${theme.space[4]}px;
  `}
`;

export const HomeView = (): JSX.Element => {
  const windowWidth = useWindowWidth();

  return (
    <Flex justifyContent="center" flexWrap="wrap">
      <Wrapper p={windowWidth < 980 ? 4 : 0} flexWrap="wrap">
        {windowWidth > 980 ? (
          <LandingPage />
        ) : (
          <TextContentWrapper>
            KOZMINSKI VENTURE LAB ECOSYSTEM OF INNOVATION SUPPORT
          </TextContentWrapper>
        )}
        <SignInSingnUpLayout />
      </Wrapper>

      {windowWidth < 980 && (
        <>
          <LandingPageSlider />
          <LandingPageFooter />
        </>
      )}
    </Flex>
  );
};
