import React from "react";
import { Flex, Box, Typography, Line } from "components";
import styled from "styled-components";
import { AvatarI } from "../Avatar/Avatar";
import CardLogo from "components/CardLogo";

export interface IChallengeCardContent {
  name: string;
  imageUrl?: string;
}

const Wrapper = styled(Flex)`
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ChallengeCardHeader: React.FC<IChallengeCardContent> = ({
                                                                       imageUrl,
                                                                       name
                                                                     }) => {
  return (
    <>
      <Wrapper flexDirection="column" alignItems="center" px={7}>
        <Box mt={4} mb={5} width="100%">
          <CardLogo imageUrl={imageUrl} />
        </Box>
        <Box width="100%">
          <StyledTypography variant="h3" color="dark" fontWeight={500}>
            {name}
          </StyledTypography>
        </Box>
        <Line />
      </Wrapper>
    </>
  );
};
