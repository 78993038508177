import React from "react";
import { Typography, Button, TextField, Flex, Box } from "components";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";

import { ReactComponent as Password } from "assets/icons/lock-password-fill.svg";
import { useForm } from "react-hook-form";
import { changePassword } from "schemas/changePassword";
import { ErrorHelperText } from "components/ErrorHelperText";
import { useChangePassword } from "hooks/useChangePassword";

interface IRegisterFormData {
  password: string;
  password_confirmation: string;
  reset_password_token: string;
}

const Wrapper = styled(Flex)`
  width: 322px;
`;

const StyledBoxWrapper = styled(Box)`
  width: 100%;
`;

export const ChangePassword = () => {
  const { changeUserPwd, error } = useChangePassword();

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<IRegisterFormData>({
    mode: "onChange",
    resolver: yupResolver(changePassword),
  });

  const onSubmit = async (data: IRegisterFormData) => {
    changeUserPwd(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper flexDirection="column" alignItems="center" mt={32}>
        <Typography variant="h1" color="primary" fontWeight={500} mb={11}>
          Change password
        </Typography>
        <StyledBoxWrapper mb={2}>
          <TextField
            type="password"
            label="Password"
            transparent
            icon={<Password />}
            {...register("password")}
            error={errors.password?.message}
          ></TextField>
        </StyledBoxWrapper>
        <StyledBoxWrapper mb={2}>
          <TextField
            type="password"
            label="Password again"
            transparent
            icon={<Password />}
            {...register("password_confirmation")}
            error={errors.password_confirmation?.message}
          ></TextField>
        </StyledBoxWrapper>
        {error && <ErrorHelperText>{error}</ErrorHelperText>}
        <Box mt={6} mb={8} width="125px">
          <Button
            variant="blue"
            fullWidth
            type="submit"
            disabled={!formState.isValid}
          >
            Reset
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};
