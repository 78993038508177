export const API_URL = process.env.REACT_APP_API_URL;

export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/signup`;
export const PASSWORD_RESET = `${API_URL}/password`;
export const LOGOUT_URL = `${API_URL}/logout`;
export const CHALLENGES = `${API_URL}/challenges`;
export const GET_CHALLENGE = (id: string | number) =>
  `${API_URL}/challenges/${id}`;
export const PUBLIC_CHALLENGES = `${API_URL}/public/challenges`;
export const GET_PUBLIC_CHALLENGE = (id: string | number) =>
  `${API_URL}/public/challenges/${id}`;
export const TEAMS = `${API_URL}/teams`;
export const TEAMS_JOIN = (id: string | number) =>
  `${API_URL}/teams/${id}/join`;
export const GET_TEAM_MEMBERS = (id: string | number) =>
  `${API_URL}/teams/${id}`;
export const UPDATE_TEAM_DATA = (id: string | number) =>
  `${API_URL}/teams/${id}`;
export const GET_INVITATIONS = (id: string | number) =>
  `${API_URL}/invitations?team_id=${id}`;
export const REMOVE_INVITATIONS = (id: string | number) =>
  `${API_URL}/invitations/${id}`;
export const CREATE_TASK = `${API_URL}/tasks`;
