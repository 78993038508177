import React, { useRef } from "react";
import styled from "styled-components";
import { Flex, Box } from "components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useGetChallenges } from "hooks/useGetChallenges";
import { Tile } from "components/Tile";
import { ReactComponent as ChevronLeft } from "assets/icons/arrow-left.svg";
import { ReactComponent as ChevronRight } from "assets/icons/arrow-right.svg";
import { useNavigate } from "react-router-dom";

import Slider from "react-slick";
import { useWindowWidth } from "hooks/useWindowWidth";

const Wrapper = styled(Flex)`
  max-width: 100%;
  position: relative;
  ${({ theme }) => `
    background-color: ${theme.palette.accent.blue};
    padding: ${theme.space[4]}px 0;
  `}
`;

const StyledButton = styled(Flex)`
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
  ${({ theme }) => `    
    border: 3px solid ${theme.palette.neutral.mainBlue};   
    background-color: ${theme.palette.neutral.white};   
  `}
`;

const ButtonContainer = styled(Flex)`
  z-index: 2;
  width: 100%;
  position: absolute;
  pointer-events: none;
`;

const StyledSlider = styled(Slider)<any>`
  width: 100%;
  min-height: 320px;
  display: inline-block;
  & .slick-track {
    height: 350px;
    display: inline-block;
  }
  & .slick-slider {
    max-height: 350px;
  }
`;

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  lazyLoad: true,
  draggable: false,
  variableWidth: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        lazyLoad: true,
      },
    },
  ],
};

export const LandingPageSlider = () => {
  const { challenges } = useGetChallenges("public");
  const sliderRef = useRef<Slider>();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();

  const moveToDetailPage = (id: number) => {
    navigate(`/app/${id}/details`);
  };

  const renderChallenges = () => {
    return challenges?.map((el) => {
      return (
        <Box key={el.id} mx="1px">
          <Tile item={el} onClick={() => moveToDetailPage(el.id)} />
        </Box>
      );
    });
  };

  return (
    <>
      {challenges && (
        <Wrapper alignItems="center" justifyContent="center">
          {windowWidth > 980 && (
            <ButtonContainer justifyContent="space-between" px={2}>
              <StyledButton
                onClick={() => sliderRef?.current?.slickPrev()}
                justifyContent="center"
                alignItems="center"
              >
                <ChevronLeft />
              </StyledButton>
              <StyledButton
                onClick={() => sliderRef?.current?.slickNext()}
                justifyContent="center"
                alignItems="center"
              >
                <ChevronRight />
              </StyledButton>
            </ButtonContainer>
          )}
          <StyledSlider ref={sliderRef} {...settings}>
            {renderChallenges()}
          </StyledSlider>
        </Wrapper>
      )}
    </>
  );
};
