import React, { useState, useEffect } from "react";
import { useSpinnerContext } from "hooks/useSpinnerContext";
import { IChallenge } from "types/challenges";
import {
  fetchChallenges,
  fetchChallengesFilters,
  fetchPublicChallenges,
  fetchPublicChallengesFilters,
} from "services/challenges";

export const useGetChallenges = (expression = "all") => {
  const [challenges, setChallenges] = useState<Array<IChallenge>>([]);
  const [error, setError] = useState("");
  const { setIsLoading } = useSpinnerContext();

  const getChallenges = async (
    callBack: (filter?: any) => any,
    filter?: string
  ) => {
    setError("");
    setIsLoading(true);
    try {
      const response = filter
        ? await callBack({
            filter,
          })
        : await callBack();
      if (!response) return;
      setChallenges(response.data);
    } catch (err: any) {
      setError(err.error);
    } finally {
      setIsLoading(false);
    }
  };

  const setFetchMethod = (exp: string) => {
    switch (exp) {
      case "all":
        return getChallenges(fetchChallenges);
      case "active_challenges":
        return getChallenges(fetchChallengesFilters, exp);
      case "my_challenges":
        return getChallenges(fetchChallengesFilters, exp);
      case "public":
        return getChallenges(fetchPublicChallenges);
      case "public_active_challenges":
        return getChallenges(fetchPublicChallengesFilters, exp);
      default:
        return null;
    }
  };

  useEffect(() => {
    setFetchMethod(expression);
  }, []);

  return { challenges };
};
