import React from "react";
import { Flex } from "components";
import styled from "styled-components";
import { LandingPageFooter } from "./LandingPageFooter";
import { LandingPageHeader } from "./LandingPageHeader";
import { LandingPageSlider } from "./LandingPageSlider";

const Wrapper = styled(Flex)`
  width: calc(100% - 400px);
  min-height: 860px;

  ${({ theme }) => `  
    background-color: ${theme.palette.neutral.mainBlue};    
  `}
`;

export const LandingPage = () => {
  return (
    <Wrapper flexDirection="column" alignItems="space-between">
      <LandingPageHeader />
      <LandingPageSlider />
      <LandingPageFooter />
    </Wrapper>
  );
};
