import styled from "styled-components";

export const ErrorHelperText = styled.div`
  ${({ theme }) => `
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: ${theme.palette.accent.red};
  `};
`;
