import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createTask } from "services/tasks";
import { useSpinnerContext } from "hooks/useSpinnerContext";
import { useChallengeDedails } from "hooks/useChallengeDedails";
import { useAuthStateContext } from "hooks/useAuthContext";

interface IGoogleForm {
  googleFormUrl: string | null;
}

const controlLabels = ["Email", "Team name", "Name"];

const controlForm = (email: string, name: string, teamName: string): void => {
  const formValues = {
    [controlLabels[0]]: email,
    [controlLabels[1]]: teamName,
    [controlLabels[2]]: name
  };

  const allFormsFields = document.querySelectorAll(".form-group");

  allFormsFields.forEach(element => {
    const labelElement = element.querySelector("label");
    const labelText = labelElement?.innerText;
    const inputElement = element.querySelector("input");

    controlLabels.forEach(label => {
      if (labelText?.includes(label)) {
        element.classList.add("hide-form-field");
        if (inputElement) {
          inputElement.value = formValues[label];
        }
      }
    });
  });
};

export const GoogleForm: React.FC<IGoogleForm> = ({ googleFormUrl }) => {
  const navigate = useNavigate();
  const { setIsLoading } = useSpinnerContext();
  const { id } = useParams<{id: string}>();
  const parsedId = parseInt(id as string, 10);
  const { responseData } = useChallengeDedails(parsedId);
  const [error, setError] = useState("");
  const [formSubmited, setFormSubmited] = useState(false);
  const { userProfile } = useAuthStateContext();


  const handleOnClick = async () => {
    if (!responseData) return;

    try {
      await createTask(
        responseData.your_team.id,
        responseData.your_team.challenge_id
      );
    } catch (err: any) {
      setError(err.error);
    } finally {
      setIsLoading(false);
      navigate("/app/my-challenges");
    }
  };

  useEffect(() => {
    if (!formSubmited) return;
    handleOnClick();
  }, [formSubmited]);

  useEffect(() => {
    if (!googleFormUrl) return;
    const script = document.createElement("script");
    script.id = "ff-script";
    script.src = googleFormUrl;
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    const timeout = setTimeout(() => {
      const form = document.querySelector(".ff-submit");
      if (!form) return;
      form.addEventListener("click", () => {
        setFormSubmited(true);
      });

      const userEmail = userProfile?.email || "";
      const userName = `${userProfile?.profile.first_name} ${userProfile?.profile.last_name}`;
      controlForm(userEmail, userName, responseData?.your_team.name || "");
    }, 1000);

    return () => clearTimeout(timeout);
  }, [responseData]);

  return (
    <>
      <div id="ff-compose"></div>
    </>
  );
};
