import { HomeView } from "views/HomeView";
import { SignUp } from "components/SignUp";
import { SignIn } from "components/SignIn";
import { ResetPassword } from "components/ResetPassword";
import { MainView } from "views/MainView";
import { ChangePassword } from "components/ChangePassword";
import { BlankPage } from "views/BlankPage";
import { AllChallenges } from "views/Challenges/AllChallenges";
import { ActiveChallenges } from "views/Challenges/ActiveChallenges";
import { MyChallenges } from "views/Challenges/MyChallenges";
import { ChallengeDetails } from "views/ChallengeDetail/ChallengeDetails";
import { ChallengeDetailsAddTeam } from "views/ChallengeDetailsAddTeam";
import { ChallengeDetailSurvey } from "views/ChallengeDetail/ChallengeDetailSurvey";
import { RequireAuth } from "components/RequireAuth";
import { RouteObject } from "react-router-dom";
import { ChallengeDetaiITeamInfo } from "views/ChallengeDetail/ChallengeDetaiITeamInfo";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <HomeView />,
    children: [
      {
        index: true,
        element: <SignIn />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
      {
        path: "/password-reset",
        element: <ResetPassword />,
      },
      {
        path: "/password-reset/new-password/",
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: "/app",
    element: <MainView />,
    children: [
      {
        index: true,
        element: <ActiveChallenges />,
      },
      {
        path: "all-challenges",
        element: <AllChallenges />,
      },
      {
        path: ":id/details",
        element: <ChallengeDetails />,
      },
      {
        path: ":id/details/team",
        element: <ChallengeDetailsAddTeam />,
      },
      {
        path: ":id/details/send-task",
        element: <ChallengeDetails horizontal={false} />,
      },
      {
        path: ":id/details/survey",
        element: <ChallengeDetailSurvey />,
      },
      {
        path: ":id/details/invitations",
        element: <ChallengeDetails horizontal={false} />,
      },
      {
        path: ":id/details/invitations/:team",
        element: <ChallengeDetaiITeamInfo />,
      },

      {
        path: "all-challenges/:id/details",
        element: <ChallengeDetails />,
      },
      {
        path: "all-challenges/:id/details/send-task",
        element: <ChallengeDetails horizontal={false} />,
      },
      {
        path: "all-challenges/:id/details/survey",
        element: <ChallengeDetailSurvey />,
      },
      {
        path: "all-challenges/:id/details/invitations",
        element: <ChallengeDetails horizontal={false} />,
      },
      {
        path: "all-challenges/:id/details/invitations/:team",
        element: <ChallengeDetaiITeamInfo />,
      },
      {
        path: "all-challenges/:id/details/team",
        element: <ChallengeDetailsAddTeam />,
      },

      {
        element: <RequireAuth />,
        children: [
          {
            path: "my-challenges",
            element: <MyChallenges />,
          },
          {
            path: "my-challenges/:id/details",
            element: <ChallengeDetails />,
          },
          {
            path: "my-challenges/:id/details/send-task",
            element: <ChallengeDetails horizontal={false} />,
          },
          {
            path: "my-challenges/:id/details/survey",
            element: <ChallengeDetailSurvey />,
          },
          {
            path: "my-challenges/:id/details/invitations",
            element: <ChallengeDetails horizontal={false} />,
          },
          {
            path: "my-challenges/:id/details/invitations/:team",
            element: <ChallengeDetaiITeamInfo />,
          },
          {
            path: "my-challenges/:id/details/team",
            element: <ChallengeDetailsAddTeam />,
          },
          {
            path: "profile",
            element: <BlankPage />,
            children: [
              {
                path: "settings",
                element: <BlankPage />,
              },
            ],
          },
        ],
      },
    ],
  },
];
