import qs from "qs";
import {
  CHALLENGES,
  GET_CHALLENGE,
  PUBLIC_CHALLENGES,
  GET_PUBLIC_CHALLENGE,
} from "constatns/paths";
import { fetchAPI } from "utilities/fetchAPI";
import {
  IFetchAllChallengeResponse,
  IFetchChallengeResponse,
  IPublicChallengesResponse,
  IFetchPublicChallengesFiltersResponse,
  IFetchPublicChallengeResponse,
  IFetchChallengesFiltersResponse,
  IFetchChallengesFilters,
} from "types/challenges";

export const fetchChallenges =
  async (): Promise<IFetchAllChallengeResponse> => {
    const response = await fetchAPI<IFetchAllChallengeResponse>(
      `${CHALLENGES}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  };

export const fetchChallenge = async ({
  id = 0,
} = {}): Promise<IFetchChallengeResponse> => {
  const response = await fetchAPI<IFetchChallengeResponse>(GET_CHALLENGE(id), {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const fetchPublicChallenges =
  async (): Promise<IPublicChallengesResponse> => {
    const response = await fetchAPI<IPublicChallengesResponse>(
      `${PUBLIC_CHALLENGES}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  };

export const fetchPublicChallenge = async ({
  id = 0,
} = {}): Promise<IFetchPublicChallengeResponse> => {
  const response = await fetchAPI<IFetchPublicChallengeResponse>(
    GET_PUBLIC_CHALLENGE(id),
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const fetchPublicChallengesFilters = async ({
  filter,
}: IFetchChallengesFilters): Promise<IFetchPublicChallengesFiltersResponse> => {
  const query = qs.stringify({
    filter,
  });

  const path = `${PUBLIC_CHALLENGES}?${query}`;

  const challengesData = await fetchAPI<IFetchPublicChallengesFiltersResponse>(
    path,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return challengesData;
};

export const fetchChallengesFilters = async ({
  filter,
}: IFetchChallengesFilters): Promise<IFetchChallengesFiltersResponse> => {
  const query = qs.stringify({
    filter,
  });

  const path = `${CHALLENGES}?${query}`;

  const challengesData = await fetchAPI<IFetchChallengesFiltersResponse>(path, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return challengesData;
};
