import { theme as themeConfig } from "config";
import React from "react";
import styled, { css } from "styled-components";
import { space, SpaceProps, variant } from "styled-system";

export type TypographyType = {
  variant: keyof typeof themeConfig.textStyles;
  children: React.ReactNode;
  color?: "primary" | "secondary" | "coloured" | "white" | "dark" | "grey";
  fontWeight?: 400 | 500 | 700;
  textAlign?: "center" | "start" | "end";
} & SpaceProps;

export const Typography = styled.div<TypographyType>(
  variant({
    scale: "textStyles",
  }),
  ({ theme, color, fontWeight, textAlign }) => css`
    ${color === "primary" ? `color: ${theme.palette.text.primary};` : null}
    ${color === "secondary" ? `color: ${theme.palette.text.secondary};` : null}
    ${color === "coloured" ? `color: ${theme.palette.neutral.mainBlue};` : null}
    ${color === "white" ? `color: ${theme.palette.neutral.white};` : null}
    ${color === "dark" ? `color: ${theme.palette.neutral.dark};` : null}
    ${color === "grey" ? `color: ${theme.palette.neutral.grey};` : null}
    ${fontWeight ? `font-weight: ${fontWeight};` : ""}
    ${textAlign ? `text-align: ${textAlign};` : ""}
  `,
  space
);
