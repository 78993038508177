import React from "react";
import {Typography, Button, Link, TextField, Flex, Box} from "components";
import {yupResolver} from "@hookform/resolvers/yup";
import styled from "styled-components";
import {ReactComponent as Email} from "assets/icons/user.svg";
import {ReactComponent as Password} from "assets/icons/lock-password-fill.svg";
import {useForm} from "react-hook-form";
import {loginSchema} from "schemas/login";
import {useLogin} from "hooks/useSignIn";
import {ErrorHelperText} from "components/ErrorHelperText";
import {ILoginFormData} from "types/forms";
import { useWindowWidth } from "hooks/useWindowWidth";

const Wrapper = styled(Flex)`
  @media only screen and (min-width: 980px) {
    width: 322px;  
  }
`;

export const SignIn = () => {
  const {login, error} = useLogin();
  const {
    register,
    handleSubmit,
    formState,
    formState: {errors},
  } = useForm<ILoginFormData>({
    mode: "onChange",
    resolver: yupResolver(loginSchema),
  });

  const windowWidth = useWindowWidth();

  const onSubmit = async (data: ILoginFormData) => {
    login(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper flexDirection="column" alignItems="center" mt={windowWidth > 980 ? 32 : 0} mb={4}>
        <Typography variant="h1" color="primary" fontWeight={500} mb={11}>
          Log in
        </Typography>
        <Box width="100%" mb={3}>
          <TextField
            label="User mail"
            transparent
            icon={<Email/>}
            {...register("email")}
            error={errors.email?.message}
          />
        </Box>
        <Box width="100%" mb={2}>
          <TextField
            type="password"
            label="Password"
            transparent
            icon={<Password/>}
            {...register("password")}
            error={errors.password?.message}
          />
          {error && <ErrorHelperText>{error}</ErrorHelperText>}
        </Box>
        <Box display="flex" justifyContent="end" width="100%">
          <Link to="/password-reset">Forgot your password?</Link>
        </Box>
        <Box mt={6} mb={8} width="125px">
          <Button
            variant="blue"
            fullWidth
            type="submit"
            disabled={!formState.isValid}
          >
            Sign In
          </Button>
        </Box>
        <Flex>
          <Typography variant="body" color="primary" mr={1}>
            Don`t have an account?
          </Typography>
          <Link to="/signup">
            <Typography variant="h3" color="coloured">
              Sign up
            </Typography>
          </Link>
        </Flex>
      </Wrapper>
    </form>
  );
};
