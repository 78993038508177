import React from "react";
import { Flex } from "components";
import { IChallenge } from "types/challenges";
import { ChallengeCard } from "components/ChallengeCard";

export interface IChallenges {
  items?: Array<IChallenge> | null;
  statusBar?: boolean;
}

export const ChallengesList: React.FC<IChallenges> = ({
  items,
  statusBar = false,
}) => {
  return (
    <Flex flexWrap="wrap" pt={4} justifyContent="center">
      {items &&
        items.map((item) => {
          return (
            <ChallengeCard
              key={item.name}
              item={item}
              statusBar={statusBar}
              buttonLabel="Details"
            />
          );
        })}
    </Flex>
  );
};
