import {
  TEAMS,
  TEAMS_JOIN,
  UPDATE_TEAM_DATA,
  GET_TEAM_MEMBERS
} from "constatns/paths";
import { fetchAPI } from "utilities/fetchAPI";
import {
  ICreateTeam,
  ICreateTeamResponse,
  IGetTeamMembers,
  IGetTeamMembersResponse,
  IUpdateTeamData,
  IFetchTeamResponse
} from "../types/teams";

export const fetchTeam = async (id: string | number) =>
  await fetchAPI<IFetchTeamResponse>(TEAMS + `/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  });

export const createTeam = async (data: ICreateTeam) =>
  await fetchAPI<ICreateTeamResponse>(TEAMS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify(data)
  });

export const getTeamMembers = async (
  userId: string
): Promise<IGetTeamMembers> => {
  const response = await fetchAPI<IGetTeamMembersResponse>(
    GET_TEAM_MEMBERS(userId),
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    }
  );
  return response;
};

export const updateTeamData = async (id: string | number, data: IUpdateTeamData) =>
  await fetchAPI<IFetchTeamResponse>(
    UPDATE_TEAM_DATA(id),
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify(data)
    }
  );

export const joinTeam = async (
  id: string | number,
  data: { accepted_regulation: boolean }
) =>
  await fetchAPI<IGetTeamMembersResponse>(TEAMS_JOIN(id), {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify(data)
  });
