import React from "react";
import styled from "styled-components";
import { Flex } from "components";
import { ReactComponent as YouTubeIcon } from "assets/icons/ytube-grey.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram-grey.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedIn-grey.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/fbook-grey.svg";

const Wrapper = styled(Flex)`
  width: 100%;
`;

export const Footer = () => {
  return (
    <Wrapper mb={6} justifyContent="space-evenly">
      <a
        href="https://www.youtube.com/channel/UCcgXEzT2DUfgux_Q8GDLvYg"
        target="_blank"
        rel="noopener noreferrer"
      >
        <YouTubeIcon />
      </a>
      <a
        href="https://www.instagram.com/kozminskihub"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramIcon />
      </a>
      <a
        href="https://www.linkedin.com/company/kozminski-business-hub"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedInIcon />
      </a>
      <a
        href="https://www.facebook.com/KozminskiBusinessHub"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookIcon />
      </a>
    </Wrapper>
  );
};
