import React, { useEffect, useState } from "react";
import { Box, Typography } from "components";
import styled from "styled-components";
import { ReactComponent as UserGroup } from "assets/icons/usergroup-outlined.svg";
import { fetchTeam } from "../../services/teams";
import { IFetchTeamResponse } from "../../types/teams";
import { useSpinnerContext } from "../../hooks/useSpinnerContext";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const StyledIcon = styled(UserGroup)`
  position: relative;
  top: 4px;
  margin-right: 10px;
`;

const TeamInvitationButton = styled.div`
  ${({ theme }) => `
  padding-left: 20px;
  background-color: ${theme.palette.neutral.lightBlue};
  color: ${theme.palette.neutral.mainBlue};
  height: 44px;
  width: 100%;
  border-radius: 3px;
  line-height: 44px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
      &:hover {    
        font-weight: 500;
        background-color: ${theme.palette.neutral.mainBlue};        
        color: ${theme.palette.neutral.lightBlue};     
      }
      &: hover ${StyledIcon} {
        & .icon-main {
          fill: ${theme.palette.neutral.lightBlue};
        }
      }
  `}
`;

export const ChallengeDetailYourInvitations: React.FC<{ teamIds: number[] }> =
  ({ teamIds }) => {
    const [teams, setTeams] = useState<IFetchTeamResponse[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { setIsLoading } = useSpinnerContext();

    useEffect(() => {
      setIsLoading(true);
      teamIds.map((id) => {
        fetchTeam(id)
          .then((res) => setTeams((prevState) => [...prevState, res]))
          .catch((err) => toast.error("Failed to retrieve the data."))
          .finally(() => setIsLoading(false));
      });
    }, []);

    const moveInvitationDetails = (id: string | number) => {
      navigate(`${location.pathname}/${id}`);
    };

    return (
      <Box px={7}>
        <Typography variant="body" color="coloured" fontWeight={500} mb={4}>
          Your team invitations
        </Typography>
        {teams.map((team) => (
          <Box key={team.data.id} width={"200px"} mt={2}>
            <TeamInvitationButton
              onClick={() => moveInvitationDetails(team.data.id)}
            >
              <StyledIcon />
              {team.data.name}
            </TeamInvitationButton>
          </Box>
        ))}
      </Box>
    );
  };
