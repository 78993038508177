import styled from "styled-components";
import {
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  LayoutProps,
  layout,
} from "styled-system";

interface IBox extends SpaceProps, FlexboxProps, LayoutProps {}

export const Box = styled.div<IBox>(space, flexbox, layout);
