import React from "react";
import styled from "styled-components";
import { ReactComponent as Illustration } from "assets/images/main-illustration.svg";

import { Flex, Box } from "components";

const Wrapper = styled(Flex)`
  height: 350px;
`;

const TextContentWrapper = styled(Box)`
  ${({ theme }) => `
    color: ${theme.palette.neutral.white};
    font-size: 32px;
    font-weight: 500;
  `}
`;

export const LandingPageHeader = () => {
  return (
    <Wrapper alignItems="center" justifyContent="space-between" p={15}>
      <TextContentWrapper ml={17}>
        KOZMINSKI VENTURE LAB ECOSYSTEM OF INNOVATION SUPPORT
      </TextContentWrapper>
      <Box mx={22}>
        <Illustration />
      </Box>
    </Wrapper>
  );
};
