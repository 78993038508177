import { GlobalStyles } from "GlobalStyles";
import { ThemeProvider } from "styled-components";
import { SpinnerContextProvider } from "contexts/SpinnerContext";
import { AuthContextProvider } from "contexts/AuthContext";

import { theme } from "config/theme";
import { BrowserRouter } from "react-router-dom";

export const AppProviders: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <SpinnerContextProvider>
        <AuthContextProvider>
          <BrowserRouter>{children}</BrowserRouter>
        </AuthContextProvider>
      </SpinnerContextProvider>
      <GlobalStyles />
    </ThemeProvider>
  );
};
