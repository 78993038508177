import { CREATE_TASK } from "constatns/paths";
import { ITasks } from "types/tasks";
import { http } from "utilities/http";
import { fetchAPI } from "utilities/fetchAPI";

export const createTask = async (teamId: number, challengeId: number) => {
  await fetchAPI<ITasks>(CREATE_TASK, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      task: { team_id: teamId, challenge_id: challengeId },
    }),
  });
};
