import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => `
    html {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;     
    }

    *,
    *:before,
    *:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
    }

    body {
      margin: 0;
      font-family: "Rubik", sans-serif;
      font-size: 14px;
      line-height: 1.4;
   

      #root {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
      }
    }

    select {
      font-family: "Rubik", sans-serif;
    }
  `}
`;
