import { useState } from "react";
import { useSpinnerContext } from "hooks/useSpinnerContext";
import { changeUserPassword } from "services/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IChangePassword } from "types/forms";

interface IUseChangePassword {
  changeUserPwd: (data: IChangePassword) => void;
  error: string | null;
}

export const useChangePassword = (): IUseChangePassword => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [error, setError] = useState("");
  const { setIsLoading } = useSpinnerContext();

  const changeUserPwd = async (data: IChangePassword) => {
    setError("");
    setIsLoading(true);

    if (!token) return;
    const dataToFetch = { ...data, reset_password_token: token };

    try {
      await changeUserPassword(dataToFetch);
      setIsLoading(false);
      navigate("/");
    } catch (err: any) {
      setError(err.error);
      setIsLoading(false);
    }
  };

  return { changeUserPwd, error };
};
