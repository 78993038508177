import { rgba } from "utilities/rgba";

import { breakpoints } from "./breakpoints";

const palettePrimaryMain = "#499CDD";

export const theme = {
  palette: {
    primary: {
      main: palettePrimaryMain,
      dark: "#a26e2a",
    },
    neutral: {
      mainBlue: "#499CDD",
      dark: "#283038",
      grey: "#6A7482",
      lightGrey: "#E2E2E2",
      lightBlue: "#DFF2FB",
      light: "#EFEFEF",
      white: "#FFFFFF",
      veryLight: "#F6F6F6",
      medium: "#BEC9D5",
    },
    accent: {
      yellow: "#F5D73F",
      orange: "#F5843F",
      lightBlue: "#26B9E7",
      blue: "#76b7e5",
      red: "#FB5C00",
      green: "#D09B34",
      lightGreen: "#FFF4DE",
    },
    text: {
      primary: "#6A7482",
      secondary: rgba("##283038", 0.7),
    },
    background: "#E2E2E2",
  },
  textStyles: {
    body2: {
      fontSize: 12,
      lineHeight: "16px",
    },
    body: {
      fontSize: 16,
      lineHeight: "22px",
    },
    h4: {
      fontSize: 12,
      lineHeight: "20px",
      fontWeight: 700,
    },
    h3: {
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 700,
    },
    h2: {
      fontSize: 20,
      lineHeight: "22px",
      fontWeight: 700,
    },
    h1: {
      fontSize: 24,
      lineHeight: "28px",
      fontWeight: 700,
    },
  },
  space: new Array(50).fill(null).map((value, index) => index * 4),
  radii: new Array(5).fill(null).map((value, index) => index * 4),
  // those are needed here for styled-system to work correctly
  breakpoints: breakpoints.map((breakpoint) => `${breakpoint}px`),
};
