import * as yup from "yup";

export const registerSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter your email")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email format"
    ),
  password: yup
    .string()
    .min(6, "The password must contain at least 6 characters.")
    .max(18, "The password can be up to 18 characters long.")
    .required("Please enter your password"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Password must match."),
  first_name: yup.string().required("Please enter your name"),
  last_name: yup.string().required("Please enter your surname"),
  rules: yup.boolean().oneOf([true], "Please accept terms and conditions"),
});
