import { SpinnerContext } from "contexts/SpinnerContext";
import { useContext } from "react";

export const useSpinnerContext = () => {
  const context = useContext(SpinnerContext);
  if (!context) {
    throw Error(
      "useSpinnerContext must be used inside an SpinnerContextProvider"
    );
  }
  return context;
};
