import React from "react";
import { Flex, Box, Typography, Line } from "components";
import { stringToArray } from "helpers/methods";
import { IChallenge, IPublicChallenge } from "types/challenges";
import { useWindowWidth } from "hooks/useWindowWidth";
import CardLogo from "components/CardLogo";

interface IChallengeDetailHeader {
  res: IChallenge | IPublicChallenge;
  imageUrl?: string;
}

export const ChallengeDetailHeader: React.FC<IChallengeDetailHeader> = ({
                                                                          res,
                                                                          imageUrl
                                                                        }) => {
  const stringArrFinish = stringToArray(res?.finish_at as string, " ");
  const stringArrStart = stringToArray(res?.start_at as string, " ");
  const startDate = stringArrStart[0].replace(".20", ".");
  const finishDate = stringArrFinish[0].replace(".20", ".");

  const windowWidth = useWindowWidth();

  return (
    <>
      <Box mt={4} mb={5} width="100%">
        <CardLogo imageUrl={imageUrl} />
      </Box>
      <Flex justifyContent={windowWidth > 980 ? "space-between" : "start"}
            flexWrap={windowWidth > 980 ? "nowrap" : "wrap"}>
        <Typography variant="h3" color="dark" fontWeight={500}>
          {res.name}
        </Typography>
        <Flex>
          <Flex alignItems="center">
            <Typography variant="body2" color="primary" fontWeight={400}>
              Start:
            </Typography>
            <Typography
              variant="body"
              color="primary"
              fontWeight={500}
              mr={windowWidth > 980 ? 6 : 1}
              ml={windowWidth > 980 ? 2 : 1}
            >
              {startDate}
            </Typography>
          </Flex>
          <Flex alignItems="center">
            <Typography variant="body2" color="primary" fontWeight={400}>
              Finish:
            </Typography>
            <Typography
              variant="body"
              color="primary"
              fontWeight={500}
              mr={windowWidth > 980 ? 6 : 1}
              ml={windowWidth > 980 ? 2 : 1}
            >
              {finishDate}
            </Typography>
          </Flex>
        </Flex>
      </Flex>
      <Line />
    </>
  );
};
