import React from "react";
import { Flex } from "components";
import styled from "styled-components";

export interface IStatusBar {
  status: string | null;
}

const Wrapper = styled(Flex)<IStatusBar>`
  ${({ theme, status }) => `
    height: 36px;
    color: ${
      (status === "active" && `${theme.palette.neutral.mainBlue}`) ||
      (status === "pending" && `${theme.palette.accent.green}`) ||
      (status === "finished" && `${theme.palette.neutral.mainBlue}`)
    };
    background-color: ${
      (status === "active" && `${theme.palette.neutral.lightBlue}`) ||
      (status === "pending" && `${theme.palette.accent.lightGreen}`) ||
      (status === "finished" && `${theme.palette.accent.lightGreen}`)
    }; 
   
  `}
`;

export const StatusBar: React.FC<IStatusBar> = ({ children, status }) => {
  return (
    <Wrapper justifyContent="center" alignItems="center" status={status}>
      {children}
    </Wrapper>
  );
};
