import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { Modal } from "../Modal";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { Button } from "../Button";
import { Typography } from "../Typography";
import { TextField } from "../TextField";
import styled from "styled-components";
import { PersonCard } from "../PersonCard";
import { PersonCardWrapper } from "../PersonCard/PersonCard";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { teamEmailSchema, teamModalSchema } from "../../schemas/teams";
import { ICreateTeamForm } from "../../types/forms";
import { ReactComponent as UsergroupOutlined } from "assets/icons/usergroup-outlined.svg";
import { IInvitation } from "../../types/invitations";
import { useWindowWidth } from "hooks/useWindowWidth";

export interface ITeamModal {
  initData?: {
    name: string;
    participants: IInvitation[];
  };
  isOpen: boolean;
  onCancelClick: () => void;
  onSubmit: (data: ICreateTeamForm) => void;
  editMode?: boolean;
}

const LeftPart = styled(Box)`
  padding-right: 44px;

  @media only screen and (min-width: 980px) {
    max-width: 380px;
    min-width: 380px;
    border-right: 1px solid
      ${({ theme }) => `${theme.palette.neutral.lightGrey}`};
  }
`;
const RightPart = styled(Box)`
  @media only screen and (min-width: 980px) {
    padding-left: 44px;
    min-width: 300px;
  }
  
  ${PersonCardWrapper} {
  ${({ theme }) => `
      margin-bottom: ${theme.space[2]}px;
    `}
`;

export const TeamModal: React.FC<ITeamModal> = ({
  initData,
  isOpen,
  onCancelClick,
  onSubmit,
  editMode,
}) => {
  const [acceptedParticipants, setAcceptedParticipants] = useState<string[]>(
    []
  );

  const windowWidth = useWindowWidth();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ICreateTeamForm & { email: string }>({
    resolver: yupResolver(teamModalSchema),
  });
  const watcher = watch();

  const {
    register: registerEmail,
    watch: watchEmail,
    setValue: setEmailValue,
    trigger,
    formState: { errors: errorsEmail },
  } = useForm<{ email: string }>({
    resolver: yupResolver(teamEmailSchema),
  });
  const watcherEmail = watchEmail();

  const pushEmail = () => {
    trigger("email").then((res) => {
      if (!res) return;
      const updatedUsers = Array.isArray(watcher.participants)
        ? [...watcher.participants]
        : [];
      if (!updatedUsers.includes(watcherEmail.email))
        updatedUsers.push(watcherEmail.email);
      setValue("participants", updatedUsers);
      setEmailValue("email", "");
    });
  };
  const handlePressEnter = (e: BaseSyntheticEvent<KeyboardEvent>) =>
    e.nativeEvent.key === "Enter" && pushEmail();
  const handleIconClick = (e: BaseSyntheticEvent) =>
    ["DIV", "svg"].includes(e.target.tagName) && pushEmail();

  useEffect(() => {
    if (initData) {
      const accepted: string[] = [];
      const allParticipants: string[] = [];
      initData.participants.forEach((el) => {
        if (el.state === "accepted") {
          accepted.push(el.email);
          allParticipants.unshift(el.email);
        } else {
          allParticipants.push(el.email);
        }
      });
      setAcceptedParticipants(accepted);
      setValue("participants", allParticipants);
      setValue("name", initData.name);
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <form>
        <Box p={10}>
          <Flex flexWrap={windowWidth > 980 ? "nowrap" : "wrap"}>
            <LeftPart>
              <Typography variant="h2" fontWeight={400} color="coloured">
                {editMode ? "Your team" : "Team name"}
              </Typography>
              <Box my={6}>
                <TextField
                  type="text"
                  label="Team name"
                  transparent
                  {...register("name")}
                  error={errors.name?.message}
                />
              </Box>
              <div onKeyDown={handlePressEnter} onClick={handleIconClick}>
                <TextField
                  type="text"
                  label="Invite friends"
                  transparent
                  {...registerEmail("email")}
                  error={errorsEmail.email?.message}
                  icon={<UsergroupOutlined />}
                />
              </div>
            </LeftPart>
            <RightPart>
              <Typography mb={6} variant="h3" fontWeight={400}>
                Invitations
              </Typography>
              {watcher.participants &&
                watcher.participants.map((user, index) => (
                  <PersonCard
                    key={user + index}
                    name={user}
                    status={
                      acceptedParticipants.includes(user)
                        ? "accepted"
                        : undefined
                    }
                    onRemove={() =>
                      setValue(
                        "participants",
                        watcher.participants.filter((el) => el !== user)
                      )
                    }
                  />
                ))}
            </RightPart>
          </Flex>
          <Flex mt={10} justifyContent="flex-end">
            <Button
              type="button"
              onClick={handleSubmit(async (data) =>
                onSubmit({ ...data, participants: data.participants || [] })
              )}
            >
              {editMode ? "Save changes" : "Create team"}
            </Button>
          </Flex>
        </Box>
      </form>
    </Modal>
  );
};
