import React, { useEffect, useState } from "react";
import { Flex, Card, Button, Box, Link, Typography } from "components";
import styled from "styled-components";
import { useChallengeDedails } from "hooks/useChallengeDedails";
import { useParams, useNavigate } from "react-router-dom";
import { Checkbox } from "components/Checkbox";
import { useForm, Controller } from "react-hook-form";
import { openLinkFromAnotherDomain } from "helpers/methods";
import { TeamModal } from "../components/TeamModal";
import { ICreateTeam } from "../types/teams";
import { ICreateTeamForm } from "../types/forms";
import { PersonCard } from "../components/PersonCard";
import { PersonCardWrapper } from "../components/PersonCard/PersonCard";
import { createTeam } from "../services/teams";
import { useSpinnerContext } from "../hooks/useSpinnerContext";
import { toast } from "react-toastify";
import { useAbsolutePath } from "hooks/useAbsolutePath";
import { ChallengeDetailHeader } from "views/ChallengeDetail/ChallengeDetailHeader";

const StyledCard = styled(Card)`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: ${theme.space[10]}px 0px;   
  `}
`;

const TeamWrapper = styled(Box)`
  ${PersonCardWrapper} {
    ${({ theme }) => `
      max-width: 300px;
      margin-bottom: ${theme.space[2]}px;
    `}
  }
`;

export const ChallengeDetailsAddTeam = () => {
  const [team, setTeam] = useState<ICreateTeam | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>();
  const { id } = useParams<{id: string}>()
  const parsedId = parseInt(id as string, 10);
  const { responseData } = useChallengeDedails(parsedId);
  const { setIsLoading } = useSpinnerContext();
  const { resolvedPath } = useAbsolutePath();
  const navigate = useNavigate();

  const { formState, handleSubmit, control } = useForm({
    mode: "onChange",
  });

  const handleSetTeam = (data: ICreateTeamForm) => {
    setTeam({
      team: {
        name: data.name,
        challenge_id: parsedId,
      },
      invited_users_emails: data.participants,
      accepted_regulation: false,
    });
    setIsModalOpen(false);
  };

  const handleCreateTeam = () => {
    if (!team) {
      toast.error("You need to add a team.");
      return;
    }
    setIsLoading(true);
    createTeam({ ...team, accepted_regulation: true })
      .then(() => navigate(`${resolvedPath}/${id}/details/send-task`))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (formState.isValid === formState.isDirty) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [formState]);

  return (
    <>
      <StyledCard>
        {responseData && (
          <>
            <Flex flexGrow={3} flexDirection="column">
              <Flex flexDirection="column" py={6} px={13}>
                <ChallengeDetailHeader res={responseData} />
                <Box>
                  {team ? (
                    <Button onClick={() => setTeam(null)} variant="lightBlue">
                      Delete team
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setIsModalOpen(true)}
                      variant="lightBlue"
                    >
                      Add team
                    </Button>
                  )}
                </Box>
                {team && (
                  <TeamWrapper mt={4}>
                    <Typography mb={4} variant="h3" color="dark">
                      {team.team.name}
                    </Typography>
                    {team.invited_users_emails.map((email) => (
                      <PersonCard key={email} name={email} />
                    ))}
                  </TeamWrapper>
                )}
                <form>
                  <Flex mt={6} flexDirection="column">
                    {responseData.regulations.map((el, index) => (
                      <Flex mb={2} key={index + el.id} alignItems="flex-start">
                        <Controller
                          control={control}
                          name={`regulationAcceptation_${el.id}_${index}`}
                          rules={{ required: el.required }}
                          render={({ field: { onChange } }) => (
                            <Checkbox
                              error={
                                !!formState.errors[
                                  `regulationAcceptation_${el.id}_${index}`
                                ]
                              }
                              onChange={onChange}
                              id={`regulationAcceptation_${el.id}_${index}`}
                            />
                          )}
                        />
                        {el.regulation_url ? (
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              openLinkFromAnotherDomain(el.regulation_url);
                            }}
                          >
                            <Link target="_blank" to={el.regulation_url}>
                              {el.link_name}
                            </Link>
                          </span>
                        ) : (
                          <label
                            htmlFor={`regulationAcceptation_${el.id}_${index}`}
                          >
                            {el.link_name}
                          </label>
                        )}
                      </Flex>
                    ))}
                  </Flex>
                </form>
              </Flex>
            </Flex>
            <Flex justifyContent="flex-end" mb={30} mr={30}>
              <Button
                onClick={handleSubmit(handleCreateTeam)}
                variant="blue"
                disabled={!isButtonDisabled}
              >
                Join
              </Button>
            </Flex>
          </>
        )}
      </StyledCard>
      <TeamModal
        key={+!!team}
        isOpen={isModalOpen}
        onCancelClick={() => setIsModalOpen(false)}
        onSubmit={handleSetTeam}
      />
    </>
  );
};
