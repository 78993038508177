import { http } from "./http";

export const fetchAPI = async <T>(url: string, options?: RequestInit) => {
  return http<T>(url, {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
