import React from "react";
import { NavLink, LinkProps } from "react-router-dom";
import styled from "styled-components";

export interface ILink {
  to: LinkProps["to"];
}

const StyledLink = styled(NavLink)`
  ${({ theme }) => `
    display: block;
    color: ${theme.palette.neutral.grey};
    text-decoration: none;
    font-size: 16px;
  `};
`;

export const NavigationLink: React.FC<ILink> = ({ children, to }) => (
  <StyledLink to={to}>{children}</StyledLink>
);
