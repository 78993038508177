import React from "react";
import { Flex, Box, Typography } from "components";
import styled from "styled-components";
import { ReactComponent as DotIcon } from "assets/icons/dot.svg";
import { stringToArray } from "helpers/methods";
import parse from "html-react-parser";

export interface IChallengeCardContent {
  start: string;
  finish?: string;
  description?: string;
  prizes?: string;
  people?: string;
  languages?: string;
}

const StyledDotIcon = styled(DotIcon)`
  margin-bottom: 2px;
`;

const StyledDescription = styled(Typography)`
  overflow-wrap: break-word;
  word-break: break-word;
`;

const DescriptionWrapper = styled(Flex)`
  min-height: 170px;
`;

const StyledBox = styled(Box)`
  width: 100%;
  min-height: 140px;
`;

export const ChallengeCardContent: React.FC<IChallengeCardContent> = ({
  start,
  finish,
  description,
}) => {
  const stringArrFinish = stringToArray(finish as string, " ");
  const stringArrStart = stringToArray(start as string, " ");

  const startDate = stringArrStart[0].replace(".20", ".");
  const finishDate = stringArrFinish[0].replace(".20", ".");
  const startTime = stringArrStart[1].slice(0, 5);
  const finishTime = stringArrFinish[1].slice(0, 5);

  return (
    <>
      <Flex flexDirection="column" alignItems="center" px={7}>
        <StyledBox>
          <Flex alignItems="center" mb={2}>
            <Typography variant="body2" color="primary" fontWeight={400}>
              Start:
            </Typography>
            <Typography variant="body" color="primary" fontWeight={500} ml={3}>
              {startDate} <StyledDotIcon /> {startTime}
            </Typography>
          </Flex>
          <Flex alignItems="center">
            <Typography variant="body2" color="primary" fontWeight={400}>
              Finish:
            </Typography>
            <Typography variant="body" color="primary" fontWeight={500} ml={1}>
              {finishDate} <StyledDotIcon /> {finishTime}
            </Typography>
          </Flex>
          {description && (
            <DescriptionWrapper>
              <Typography
                variant="body2"
                color="primary"
                fontWeight={400}
                mt={4}
              >
                Description:
              </Typography>
              <StyledDescription
                variant="body"
                color="primary"
                fontWeight={400}
                mt={3}
                ml={2}
              >
                {description && parse(description)}
              </StyledDescription>
            </DescriptionWrapper>
          )}
        </StyledBox>
      </Flex>
    </>
  );
};
