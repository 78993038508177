import React from "react";
import { Flex, Box, Typography } from "components";
import styled from "styled-components";
import { stringToArray } from "helpers/methods";
import parse from 'html-react-parser';

export interface IChallengeCardPrizes {
  prizes: string;
  people: string;
  languages: string;
}

const StyledWrapper = styled(Flex)`
  width: 100%;
`;

export const ChallengeCardPrizes: React.FC<IChallengeCardPrizes> = ({
  prizes,
  people,
  languages,
}) => {
  const renderItems = (text: string, separator: string) => {
    return stringToArray(text, separator).map((element) => {
      return (
        <Typography variant="body" color="primary" key={element} mt={1}>
          {parse(element.trim())}
        </Typography>
      );
    });
  };

  return (
    <StyledWrapper px={7}>
      <Box flexDirection="column" alignItems="center" width="100%">
        <Flex justifyContent="flex-start" flexDirection="column">
          <Typography variant="body" color="coloured" fontWeight={500}>
            Prizes
          </Typography>
          {renderItems(prizes, ",")}
          <Typography variant="body" color="coloured" fontWeight={500} mt={5}>
            People
          </Typography>
          {renderItems(people, ",")}
          <Typography variant="body" color="coloured" fontWeight={500} mt={5}>
            Language
          </Typography>
          {renderItems(languages, ",")}
        </Flex>
      </Box>
    </StyledWrapper>
  );
};
