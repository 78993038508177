import styled from "styled-components";
import React from "react";
import { invitedUserStatuses } from "../../types/user";
import { ReactComponent as AccessTimeFilled } from "assets/icons/access-time-filled.svg";
import { ReactComponent as BXX } from "assets/icons/bx-x.svg";
import { ReactComponent as TickCircleSolid } from "assets/icons/tick-circle-solid.svg";
import { rgba } from "../../utilities/rgba";
import { Flex } from "../Flex";

interface IPersonCard {
  name: string
  status?: invitedUserStatuses
  onRemove?: () => void
}

export const PersonCardWrapper = styled(Flex)`
  & svg:first-child {
    margin-left: 12px;
  }
  & svg:nth-child(2) {
    margin-left: 12px;
  }
  ${({ theme }) => `
    background-color: ${rgba(theme.palette.primary.main, 0.1)};  
  `}
`;

export const SuccessIcon = styled(TickCircleSolid)`
  ${({ theme }) => `
    width: 20px;
    height: 20px;
  `};
`;
export const WatchIcon = styled(AccessTimeFilled)`
  ${({ theme }) => `
    width: 20px;
    height: 20px;
  `};
`;
export const CloseIcon = styled(BXX)`
  cursor: pointer;
  ${({ theme }) => `
    width: 10px;
    height: 10px;
  `};
`;

export const PersonCard: React.FC<IPersonCard> = ({
                                                    name,
                                                    status,
                                                    onRemove
                                                  }) => (
  <PersonCardWrapper px={4} py={2} justifyContent="space-between" alignItems="center">
    {name}
    {status === "accepted" && <SuccessIcon />}
    {status === "pending" &&
    <Flex justifyContent="space-between" alignItems="center">
      <WatchIcon />
      {onRemove &&
      <CloseIcon onClick={onRemove} />
      }
    </Flex>
    }
    {!status && onRemove &&
    <CloseIcon onClick={onRemove} />
    }
  </PersonCardWrapper>
);
