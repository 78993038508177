import React, { useEffect } from "react";
import { Flex, Card, Button, Line, Checkbox, Link, Box } from "components";
import styled from "styled-components";
import { useChallengeDedails } from "hooks/useChallengeDedails";
import { ChallengeDetailYourInvitations } from "views/ChallengeDetail/ChallengeDetailYourInvitations";
import { ChallengeDetaiISendTask } from "views/ChallengeDetail/ChallengeDetaiISendTask";

import {
  ChallengeCardHeader,
  ChallengeCardContent,
  ChallengeCardPrizes
} from "components/ChallengeCardContent";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useWindowWidth } from "hooks/useWindowWidth";
import { Controller } from "react-hook-form";
import { downloadURI, openLinkFromAnotherDomain } from "helpers/methods";

export interface IChallengeDetails {
  horizontal?: boolean;
  waiting?: boolean;
  pending?: boolean;
}

export interface ICommonSection {
  horizontal?: boolean;
}

const StyledCard = styled(Card)`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: ${theme.space[10]}px 0px;
  `}
`;

const ContentWrapper = styled(Flex)`
  width: 100%;
`;

const StyledSection = styled(Flex)`
  width: 100%;
`;

const CommonSection = styled.div<ICommonSection>`
  ${({ theme, horizontal }) => `  
    width: 100%;    
    padding-left: ${!horizontal ? `${theme.space[9]}px` : 0};    
  `}
`;

const StyledCommonSection = styled.div`
  ${({ theme }) => `
    width: 100%;
    margin-bottom: ${theme.space[3]}px;
  `}
`;

const InterchangeableContent = styled.div`
  width: 50%;
`;

export const ChallengeDetails = ({
                                   horizontal = true
                                 }: IChallengeDetails): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const parsedId = parseInt(id as string, 10);
  const { responseData } = useChallengeDedails(parsedId);
  const location = useLocation();

  const windowWidth = useWindowWidth();

  useEffect(() => {
    horizontal && responseData && responseData["your_team"] && navigate(`../../../app/my-challenges/${responseData.id}/details/send-task`);
  }, [responseData, navigate, horizontal]);

  const moveToNextPage = () => {
    if (!responseData) return;
    responseData?.regulations
      ? navigate(`${location.pathname}/team`)
      : navigate("/");
  };

  const renderIcyContent = (status: string | null | undefined) => {
    if (!responseData) return;
    switch (status) {
      case "pending":
        return (
          <ChallengeDetailYourInvitations
            teamIds={responseData.invitations.map((el) => el.team_id)}
          />
        );
      case "active":
        return <ChallengeDetaiISendTask data={responseData} />;
      case "finished":
        return (
          <ChallengeDetailYourInvitations
            teamIds={responseData.invitations.map((el) => el.team_id)}
          />
        );
      case null:
        break;
      case undefined:
        break;
      default:
        status;
    }
  };

  return (
    <StyledCard>
      {responseData && (
        <>
          <Flex
            p={windowWidth > 980 ? 6 : 0}
            flexGrow={1}
            flexDirection="column"
          >
            <Flex>
              <StyledSection>
                <ChallengeCardHeader imageUrl={responseData.photo_url} name={responseData.name} />
              </StyledSection>
              {windowWidth > 980 && (
                <StyledSection mt={31} px={7}>
                  <Line />
                </StyledSection>
              )}
            </Flex>
            <ContentWrapper flexWrap={windowWidth > 980 ? "nowrap" : "wrap"}>
              <StyledSection
                flexGrow={1}
                flexDirection={horizontal ? "row" : "column"}
                flexWrap={windowWidth > 980 ? "nowrap" : "wrap"}
              >
                <StyledCommonSection>
                  <ChallengeCardContent
                    start={responseData.start_at}
                    finish={responseData.finish_at}
                    description={responseData.description || ""}
                  />
                </StyledCommonSection>
                <CommonSection>
                  <ChallengeCardPrizes
                    prizes={responseData.prizes}
                    people={responseData.people}
                    languages={responseData.languages}
                  />
                </CommonSection>
              </StyledSection>
              {!horizontal && (
                <InterchangeableContent>
                  {renderIcyContent(responseData.user_challenge_status?.status)}
                </InterchangeableContent>
              )}
            </ContentWrapper>
            <Box my={4} px={7}>
              {responseData.regulations_files_urls && responseData.regulations_files_urls.map((el, index) => (
                <Flex mb={2} key={index}>
                  <span onClick={(e) => {
                    e.preventDefault();
                    downloadURI(el, 'rulebook');
                  }}>
                    <Link to="#">Rulebook</Link>
                  </span>
                </Flex>
              ))}
            </Box>
          </Flex>
          {horizontal && (
            <Flex justifyContent="flex-end" mb={30} mr={30}>
              <Button
                disabled={!responseData.active}
                variant="blue"
                onClick={() => moveToNextPage()}
              >
                {responseData?.regulations ? "Join" : "Sign in"}
              </Button>
            </Flex>
          )}
        </>
      )}
    </StyledCard>
  );
};
