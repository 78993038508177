import React, { useEffect, useState, useCallback } from "react";
import { Flex, Box, Typography, Button } from "components";
import styled from "styled-components";
import { IChallenge } from "types/challenges";

import { useSpinnerContext } from "hooks/useSpinnerContext";
import { IFetchTeamResponse, IUpdateTeamData } from "types/teams";
import { fetchTeam, updateTeamData } from "services/teams";
import { PersonCard } from "components/PersonCard";
import { useAuthStateContext } from "../../hooks/useAuthContext";
import { useInvitations } from "hooks/useInvitations";
import { toast } from "react-toastify";
import { TeamModal } from "../../components/TeamModal";
import { ICreateTeamForm } from "../../types/forms";
import { deleteInvitations } from "../../services/invitations";
import { useNavigate, useParams } from "react-router-dom";
import { useAbsolutePath } from "hooks/useAbsolutePath";
import { stringToArray } from "../../helpers/methods";
import { useWindowWidth } from "hooks/useWindowWidth";

interface IChallengeDetaiISendTask {
  data: IChallenge;
}

const Wrapper = styled(Flex)`
  height: 100%;
`;

const StyledWarn = styled(Typography)`
  white-space: pre-line;
`;

const PersonWrapper = styled(Box)`
  min-width: 260px;
  width: fit-content;
`;

export const ChallengeDetaiISendTask: React.FC<IChallengeDetaiISendTask> = ({
  data,
}) => {
  const { userProfile } = useAuthStateContext();
  const { setIsLoading } = useSpinnerContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { invitations, setInvitations } = useInvitations(data.your_team.id);
  const [team, setTeam] = useState<IFetchTeamResponse | null>(null);
  const { resolvedPath } = useAbsolutePath();
  const navigate = useNavigate();
  const { id } = useParams<{id: string}>();

  const windowWidth = useWindowWidth();

  const getUsers = useCallback(async () => {
    setIsLoading(true);
    fetchTeam(`${data.your_team.id}`)
      .then((res) => setTeam(res))
      .catch((err) => toast.error("Failed to retrieve the data."))
      .finally(() => setIsLoading(false));
  }, []);

  const moveToSurvey = () => {
    navigate(`${resolvedPath}/${id}/details/survey`);
  };

  const teamMembersUpdate = async (
    id: string | number,
    data: IUpdateTeamData
  ) => {
    try {
      const teamUpdated = await updateTeamData(id, data);
      setInvitations(teamUpdated.data.invitations);
      setTeam(teamUpdated);
    } catch (err: any) {
      toast.error("Failed to update the team.");
    }
  };

  const handleSetTeam = async (data: ICreateTeamForm) => {
    if (!team) return;
    setIsLoading(true);
    const participantsToRemove = invitations
      .filter((x) => !data.participants.includes(x.email))
      .map((el) => el.id);
    const promises: Promise<void>[] = [];
    participantsToRemove.forEach((email) =>
      promises.push(deleteInvitations(email))
    );

    try {
      await Promise.all(promises);
      const acceptedUsers = invitations
        .filter((el) => el.state === "accepted")
        .map((el) => el.email);
      await teamMembersUpdate(team.data.id, {
        team: { name: data.name },
        invited_users_emails: [...data.participants, ...acceptedUsers],
      });
    } catch (err) {
      toast.error("Failed to update the team.");
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const renderTeamMembers = () =>
    invitations.map((invitation) => (
      <PersonWrapper key={invitation.id} mb={3}>
        <PersonCard name={invitation.email} status={invitation.state} />
      </PersonWrapper>
    ));

  const showUnavailableAlert = () => {
    let errMessage = "";
    if (team?.data.sent_task)
      errMessage = "Your team has already sent the task. You cannot resend it.";
    if (data.available_for_sending_tasks === false) {
      const stringArrStart = stringToArray(
        data.sending_tasks_available_from as string,
        " "
      );
      const stringArrFinish = stringToArray(
        data.sending_tasks_available_to as string,
        " "
      );
      const startDate = stringArrStart[0].replace(".20", ".");
      const finishDate = stringArrFinish[0].replace(".20", ".");
      const startTime = stringArrStart[1].slice(0, 5);
      const finishTime = stringArrFinish[1].slice(0, 5);

      errMessage = `You cannot send your application.
      It will be possible from ${startDate} ${startTime} to ${finishDate} ${finishTime}.`;
    }
    if (!data.active) errMessage = "The challenge is not active.";

    return errMessage.length > 0 ? (
      <StyledWarn variant="body" color="coloured" fontWeight={500} mb={4}>
        {errMessage}
      </StyledWarn>
    ) : (
      false
    );
  };

  const showEdit =
    invitations &&
    team &&
    userProfile &&
    data.your_team.owner_id === +userProfile.id;

  return (
    <>
      <Wrapper
        px={windowWidth > 980 ? 7 : 4}
        flexDirection="column"
      >
        <div>
          <Typography variant="body" color="coloured" fontWeight={500} mb={4}>
            Your team
          </Typography>
          <Typography variant="body" fontWeight={500} mb={6}>
            {team?.data.name}
          </Typography>
          {renderTeamMembers()}
          {showEdit && (
            <Box mt={4}>
              <Button variant="lightBlue" onClick={() => setIsModalOpen(true)}>
                Edit
              </Button>
            </Box>
          )}
        </div>
        <Flex justifyContent="flex-end" mr={windowWidth > 980 ? 30 : 0} mb={10}>
          <Box mt={6} justifyContent="center" width={windowWidth > 980 ? 300 : 0}>
            {showUnavailableAlert()}
            <Button
              variant="blue"
              disabled={
                team?.data.sent_task ||
                data.available_for_sending_tasks === false ||
                !data.active
              }
              onClick={moveToSurvey}
            >
              Send task
            </Button>
          </Box>
        </Flex>
      </Wrapper>
      {invitations && team && isModalOpen && (
        <TeamModal
          editMode
          key={invitations.length}
          initData={{
            name: team?.data.name,
            participants: invitations,
          }}
          isOpen={isModalOpen}
          onCancelClick={() => setIsModalOpen(false)}
          onSubmit={handleSetTeam}
        />
      )}
    </>
  );
};
