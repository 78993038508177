import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface IUseAbsolutePath {
  resolvedPath: string;
  trimUrl: () => void;
}

export const useAbsolutePath = (): IUseAbsolutePath => {
  const { pathname } = useLocation();
  const [resolvedPath, setResolvedPath] = useState<string>("");

  const trimUrl = () => {
    const match = pathname.match(/\d+/);
    if (!match) return;
    setResolvedPath(pathname.substring(0, match.index! - 1));
  };

  useEffect(() => {
    setResolvedPath(pathname);
    trimUrl();
  }, [pathname]);

  return { resolvedPath, trimUrl };
};
