import { useRoutes } from "react-router-dom";
import { routes } from "route/routes";
import { Spinner } from "components/Spinner";
import { useSpinnerContext } from "hooks/useSpinnerContext";
import { ToastContainer } from "react-toastify";

export const App = () => {
  const { isLoading } = useSpinnerContext();
  const appRoutes = useRoutes(routes);
  return (
    <>
      {appRoutes}
      {isLoading && <Spinner />}
      <ToastContainer position="bottom-right" />
    </>
  );
};
