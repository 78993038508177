export interface IMenuItem {
  path: string;
  label: string;
  private: boolean;
}

export const sideMenuItems = [
  {
    label: "Active Challenges",
    path: "/app",
    private: false,
  },
  {
    label: "Your Challenges",
    path: "/app/my-challenges",
    private: true,
  },
  {
    label: "All Challenges",
    path: "/app/all-challenges",
    private: false,
  },
];
