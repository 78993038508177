export const parseChallengeStatus = (status: string | null | undefined) => {
  switch (status) {
    case "active":
      return "IN PROGRESS";
    case "pending":
      return "WAITING";
    case "finished":
      return "ENDED";
    case null:
      break;
    case undefined:
      break;
    default:
      status;
  }
};
