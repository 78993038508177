import React from "react";
import { Card } from "components";
import styled from "styled-components";
import { ChallengeCardContent } from "components/ChallengeCardContent";
import { ChallengeCardHeader } from "components/ChallengeCardContent";
import { IPublicChallenge } from "types/challenges";

export interface ITile {
  item: IPublicChallenge;
  onClick: () => void;
}

interface IStyledCard {
  onClick: () => void;
}

const StyledCard = styled(Card)<IStyledCard>`
  width: 290px;
  max-height: 320px;
  cursor: pointer;
  padding-bottom: 16px;
  ${({ theme }) => `       
    margin: ${theme.space[4]}px;    
  `}
`;

export const Tile: React.FC<ITile> = ({ item, onClick }) => {
  return (
    <StyledCard onClick={onClick}>
      <ChallengeCardHeader name={item.name} />
      <ChallengeCardContent start={item.start_at} finish={item.finish_at} />
    </StyledCard>
  );
};
