import React, { useEffect, useState } from "react";
import { NavigationLink } from "components";
import { IMenuItem } from "constatns/menuItems";
import { Flex } from "components";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useAbsolutePath } from "hooks/useAbsolutePath";

export interface IMenu {
  items?: Array<IMenuItem>;
  onMenuIconClick: () => void;
}

const Wrapper = styled(Flex)`
  width: 100%;
`;

interface IListItem {
  active?: boolean;
}

const ListItem = styled(Flex)<IListItem>`
  ${({ theme, active }) => `
  height: 43px;
  background-color: ${active && theme.palette.neutral.lightBlue};    
      &:hover {    
        font-weight: 500;
        background-color: ${theme.palette.neutral.lightBlue};        
        color: ${theme.palette.neutral.mainBlue};     
      }
  `}
`;

export const Menu = ({ items, onMenuIconClick }: IMenu) => {
  const { pathname } = useLocation();
  const [active, setIsActive] = useState<string>("");
  const { resolvedPath, trimUrl } = useAbsolutePath();

  const handleOnClick = (path: string) => {
    onMenuIconClick();
    setIsActive(path);
  };

  useEffect(() => {
    setIsActive(pathname);
    trimUrl();
  }, [resolvedPath]);

  const renderItems = () => {
    if (!items) return;
    return items.map(({ path, label }) => {
      const isItemSelected = active === path;

      return (
        <NavigationLink to={path} key={label}>
          <ListItem
            active={isItemSelected}
            mb={3}
            pl={2}
            alignItems="center"
            onClick={() => handleOnClick(path)}
          >
            {label}
          </ListItem>
        </NavigationLink>
      );
    });
  };

  return (
    <Wrapper flexDirection="column" justifyContent="center" ml={8}>
      {renderItems()}
    </Wrapper>
  );
};
