import { useEffect, useState, useCallback } from "react";
import { fetchChallenge, fetchPublicChallenge } from "services/challenges";
import { useSpinnerContext } from "hooks/useSpinnerContext";
import { IChallenge } from "types/challenges";
import { useAuthStateContext } from "hooks/useAuthContext";

export const useChallengeDedails = (id: number) => {
  const [error, setError] = useState("");
  const { setIsLoading } = useSpinnerContext();
  const [responseData, setResponseData] = useState<IChallenge | null>(null);

  const { userProfile } = useAuthStateContext();

  const getChallengeData = useCallback(
    async (id: number) => {
      setError("");
      setIsLoading(true);
      try {
        const response = userProfile
          ? await fetchChallenge({ id })
          : await fetchPublicChallenge({ id });
        if (!response) return;
        setResponseData(response.data as IChallenge);
      } catch (err: any) {
        setError(err.error);
      } finally {
        setIsLoading(false);
      }
    },
    [id]
  );

  useEffect(() => {
    getChallengeData(id);
  }, []);

  return { responseData, error };
};
