import * as yup from "yup";

export const changePassword = yup.object().shape({
  password: yup
    .string()
    .min(6, "The password must contain at least 6 characters.")
    .max(18, "The password can be up to 18 characters long.")
    .required("Please enter your password"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Password must match."),
});
