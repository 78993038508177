import { useState } from "react";
import { useSpinnerContext } from "hooks/useSpinnerContext";
import { resetUserPassword } from "services/auth";
import { useNavigate } from "react-router-dom";
import { IResetPassword } from "types/forms";

interface IUseResetPassword {
  resetUserPwd: (data: IResetPassword) => void;
  error: string | null;
}

export const useResetPassword = (): IUseResetPassword => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { setIsLoading } = useSpinnerContext();

  const resetUserPwd = async (data: IResetPassword) => {
    setError("");
    setIsLoading(true);

    try {
      await resetUserPassword(data);
      setIsLoading(false);
      navigate("/");
    } catch (err: any) {
      setError(err.error);
      setIsLoading(false);
    }
  };

  return { resetUserPwd, error };
};
