import React from "react";
import { Card, Flex, Button, Typography } from "components";
import styled from "styled-components";
import { ChallengeCardContent } from "components/ChallengeCardContent";
import { ChallengeCardHeader } from "components/ChallengeCardContent";
import { IChallenge } from "types/challenges";
import { parseChallengeStatus } from "helpers/parsers";
import { StatusBar } from "components/StatusBar";
import { useNavigate, useLocation } from "react-router-dom";

export interface IChallengeCard {
  item: IChallenge;
  statusBar?: boolean;
  buttonLabel?: string;
}

const StyledCard = styled(Card)`
  flex-basis: 100%;
  ${({ theme }) => `
    min-width: 225px;
    max-width: 300px;
    margin:  ${theme.space[4]}px;
  `}
`;

export const ChallengeCard: React.FC<IChallengeCard> = ({
                                                          item,
                                                          statusBar = true,
                                                          buttonLabel
                                                        }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectDetails = (status: string | null | undefined, id: number) => {
    switch (status) {
      case "pending":
        return navigate(`${location.pathname}/${id}/details/invitations`);
      case "active":
        return navigate(`${location.pathname}/${id}/details/send-task`);
      case "finished":
        return;
      case null:
        return navigate(`${location.pathname}/${id}/details`);
      case undefined:
        return navigate(`${location.pathname}/${id}/details`);
      default:
        status;
    }
  };

  return (
    <StyledCard>
      {statusBar && (
        <StatusBar status={item.user_challenge_status?.status || null}>
          <Typography variant="body" fontWeight={500}>
            {parseChallengeStatus(item.user_challenge_status?.status)}
          </Typography>
        </StatusBar>
      )}
      <ChallengeCardHeader name={item.name} imageUrl={item.photo_url} />
      <ChallengeCardContent
        start={item.start_at}
        finish={item.finish_at}
        description={
          item.description ? item.description.substring(0, 150) + "..." : ""
        }
      />
      <Flex mt={8} mb={10} justifyContent="center">
        <Button
          variant="blue"
          onClick={() =>
            redirectDetails(item.user_challenge_status?.status, item.id)
          }
        >
          {buttonLabel}
        </Button>
      </Flex>
    </StyledCard>
  );
};
