import { useState } from "react";
import { useSpinnerContext } from "hooks/useSpinnerContext";
import { registerUser } from "services/auth";
import { useNavigate } from "react-router-dom";
import { IRegisterUserRequest } from "types/forms";
import { useAuthDispatchContext } from "hooks/useAuthContext";

interface IUseRegister {
  signup: (data: IRegisterUserRequest) => void;
  error: string | null;
}

export const useRegister = (): IUseRegister => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { setIsLoading } = useSpinnerContext();
  const { setToken, setUserProfile } = useAuthDispatchContext();

  const signup = async (data: IRegisterUserRequest) => {
    setError("");
    setIsLoading(true);

    try {
      const response = await registerUser(data);
      navigate("/app");
      if (!response.token) return;
      setToken(response.token);
      setUserProfile(response);
    } catch (err: any) {
      setError(err.error);
    } finally {
      setIsLoading(false);
    }
  };

  return { signup, error };
};
