import * as yup from "yup";

export const emailSchema = yup
  .string()
  .required("Please enter your email.")
  .matches(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "Invalid email format."
  )

export const teamModalSchema = yup.object().shape({
  name: yup.string().required("Enter team name.").min(3, 'The name must contain at least 3 symbols.'),
});

export const teamEmailSchema = yup.object().shape({
  email: emailSchema
});
